import React from 'react';
import { HStack, Heading, Img, Stack, Text, chakra } from '@chakra-ui/react';
import arrow from '../../assets/images/Vector.svg';
import pic1 from '../../assets/images/lawNews1.webp';
import pic2 from '../../assets/images/lawNews2.avif';
import flag1 from '../../assets/images/United states.png';
import flag2 from '../../assets/images/Canada.png';
const EmploymentLaw = ({ blogsData }) => {
  console.log('blogsData:', blogsData);

  function truncateDescription(description, wordCount) {
    if (!description) return '';

    const words = description.split(' ');

    if (words.length <= wordCount) {
      return description;
    } else {
      return words.slice(0, wordCount).join(' ') + '...';
    }
  }

  function truncateUrl(url, maxLength) {
    if (url?.length <= maxLength) {
      return url;
    } else {
      const truncatedUrl = url?.substring(0, maxLength - 3) + '...';
      return truncatedUrl;
    }
  }
  
  const originalUrl = 'https://www.shrm.org/resourcesandtools/legal-and-compliance/state-and-local-updates/pages/overtime-california-employers.aspx';
  const truncatedUrl = truncateUrl(originalUrl, 30);
  
  console.log(truncatedUrl);
  

  const renderBlog = blog => (
    <Stack alignItems={'center'} key={blog._id} direction={{ base: 'column', lg: 'row' }} gap={5}>
      <Img w="50%" h="30vh" borderRadius={'5px'} src={blog.mainContent} />
      <Stack mt={2} w={{ base: '100%', lg: '50%' }}>
        <Heading
          color={'#000000'}
          fontSize={{ base: '1rem', md: '1.3rem', lg: '1.1rem' }}
          fontWeight={'700'}
          textDecoration={'underline'}
          lineHeight={'25px'}
          className="heading"
        >
          {blog.title}
        </Heading>
        <Text
          color={'#343434'}
          fontSize={{ base: '0.75rem', md: '0.95rem', lg: '0.95rem' }}
          fontWeight={'500'}
        >
          {/* {blog.description} */}
          {truncateDescription(blog.description, 10)}
        </Text>
        <Text
          color={'#343434'}
          fontSize={{ base: '0.75rem', md: '0.95rem', lg: '0.95rem' }}
          fontWeight={'500'}
        >
          News URL:{' '}
          <chakra.span
            onClick={() => window.open(blog.link, '_blank')}
            cursor={'pointer'}
            _hover={{ color: '#17496D ', fontWeight: '700' }}
          >
            {/* {blog.link} */}
            {truncateUrl(blog.link, 23)}
          </chakra.span>
        </Text>
      </Stack>
    </Stack>
  );

  const renderCountrySection = country => {
    const countryBlogs = blogsData?.filter(blog => blog.country === country);

    return (
      <Stack w={{ base: '100%', lg: '50%' }} py={6}>
        <HStack w="50%" justifyContent={'center'} gap={4}>
          <Img src={country === 'USA' ? flag1 : flag2} />
          <Heading
            color={country === 'USA' ? '#5055A0' : '#BA241A'}
            fontSize={{ base: '1rem', md: '1.3rem', lg: '1.5rem' }}
            fontWeight={'700'}
            className="heading"
          >
            {country}
          </Heading>
        </HStack>
        {countryBlogs?.map(blog => renderBlog(blog))}
      </Stack>
    );
  };

  return (
    <Stack
      id="employment-law"
      width={'100%'}
      px={{ base: 3, md: 6, lg: 12, xl: 20 }}
      py={5}
      mb={5}
    >
      <Heading
        color={'#17496D'}
        fontSize={{ base: '1rem', md: '1.8rem', lg: '2rem' }}
        fontWeight={'700'}
        className="heading"
        mb={4}
      >
        Employment Law & News
      </Heading>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        gap={{base:12,md:10,lg:12,xl:'7vw'}}
        height={'fit-content'}
      >
        {renderCountrySection('USA')}
        {renderCountrySection('Canada')}
      </Stack>
    </Stack>
  );
};

export default EmploymentLaw;
