import React, { useEffect, useState } from 'react';

import {
  Heading,
  Stack,
  Text,
  Img,
  chakra,
  HStack,
  Link,
  Spacer,
} from '@chakra-ui/react';
import Layout from '../layout/Layout';
import facebook from '../../assets/images/facebook1.png';
import twitter from '../../assets/images/twitter.png';
import youtube from '../../assets/images/youtube.png';
import { useLocation } from 'react-router-dom';
import { getSingleBlogService } from '../../services/Service';
import moment from 'moment/moment';
import pic1 from '../../assets/images/HRtech.png';
import { Bars } from 'react-loader-spinner';

const TechTrendsDetail = () => {
  const [blog, setBlog] = useState();
  const [loading, setloading] = useState();
  const location = useLocation();
  console.log('🚀 ~ file: HrNews.js:24 ~ HrNews ~ location:', location?.state);

  useEffect(() => {
    setloading(true)
    if (location?.state) {
    setBlog(location.state)
    setloading(false)
    }
  }, []);
  return (
    <Layout>
        {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <Bars
              height="60"
              width="60"
              color="#17496D"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      )}
      <Stack width={'100%'} px={{ base: 3, md: 6, lg: 12, xl: 20 }} py={5}>
        <Stack>
          <Heading
            color={'#000000'}
            fontSize={{ base: '1.5rem', md: '1.4rem', lg: '2.00rem' }}
            fontWeight={'700'}
            py={4}
          >
            {blog?.title}
            {/* Empowering HR Excellence: Navigating the AI Revolution in North
            America */}
          </Heading>
          {/* <Text
            color={'#343434'}
            fontSize={{ base: '0.80rem', md: '0.90rem', lg: '1rem' }}
            fontWeight={'500'}
            lineHeight={{ base: '25px', md: '25px', lg: '25px' }}
          >
           {blog?.blog_intro}
          </Text> */}
          {/* {blog && blog?.mainContent && ( */}
          <Img
            height="90vh"
            borderRadius={'xl'}
            src={blog?.mainContent}
            objectFit={'cover'}
          />
          {/* )} */}
        </Stack>
        <Stack direction={'row'}>
          <Stack direction={'column'} my={2} spacing={3}>
            {/* <Text
              color={'#17496D'}
              fontSize={{ base: '1.100rem', md: '1.115rem', lg: '1.120rem' }}
              fontWeight={'400'}
              lineHeight={{ base: '8px', md: '10px', lg: '12px' }}
            >
              By <chakra.span color={'#FF6217'}>{blog?.author}</chakra.span>
            </Text> */}
            <Text
              color={'#17496D'}
              fontSize={{ base: '0.60rem', md: '0.70rem', lg: '0.75rem' }}
              fontWeight={'400'}
            >
              {moment(blog?.timestamp).format("MMM DD, YYYY")}
            </Text>
          </Stack>
          <Spacer />
        </Stack>
        <Stack  gap={6}>
          <Text
            color={'#343434'}
            fontSize={{ base: '1.00rem', md: '1rem', lg: '1rem' }}
            fontWeight={'400'}
            lineHeight={{ base: '25px', md: '25px', lg: '28px' }}
          >
            {blog?.description}
           
          </Text>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default TechTrendsDetail;
