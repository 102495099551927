import React, { useEffect, useState } from 'react';
import {
  Heading,
  Stack,
  Text,
  Button,
  Divider,
  HStack,
  Img,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import Layout from '../layout/Layout';
import dot from '../../assets/images/Ellipse 10.svg';
import { loadStripe } from '@stripe/stripe-js';
import Stripe from '../stripe/Stripe';
import { useNavigate } from 'react-router-dom';
import { baseuRL } from '../../environment';
import ErrorToaster from '../../utils/toaster/error/ErrorToaster';
import { Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import PaymentForm from '../stripe/PaymentForm';
import { getSingleUserService } from '../../services/Service';
import { Bars } from 'react-loader-spinner';

const getStripe = async () => {
  return await loadStripe(
    'pk_live_51OO1KTGFSjEkmDCD56EcW5fzcF5IIgvp5ChclSFSdD33IRaXcku4sqYdL946066deRvEyL0L3WAu6KTKRlUhQFIe00ZFP6LebV'
  );

  // 'pk_test_51ORvl9JBlwRUWYLYchDzUJfzN7Wydmttw5QXKYZOgILBYiEZcZkNpuCN8wp8P7d9iR6iplFQK4jB8W7OzGy2hziX00QnM90CS3'
};
const DigitalSubscription = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState('');
  const nav = useNavigate();
  const toast = useToast();

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  console.log(
    '🚀 ~ file: DigitalSubscription.js:45 ~ DigitalSubscription ~ userInfo:',
    userInfo
  );

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    const stripe = await getStripe();

    const body = {
      id: userInfo?._id,
    };

    const headers = {
      'Content-Type': 'application/json',
    };

    try {
      const response = await fetch(`${baseuRL}/api/create-checkout-session`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
      });
      console.log(
        '🚀 ~ file: DigitalSubscription.js:61 ~ handleSubmit ~ response:',
        response
      );
      const session = await response.json();
      if (response.status === 400) {
        ErrorToaster(toast, session.error);
        return;
      }

      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });
      setLoading(false);
      if (result.error) {
        setLoading(false);
        console.error(result.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    setLoading(true);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo) {
      getSingleUserService(userInfo?._id).then(res => {
        console.log(res);
        if (res.success) {
          setUserData(res.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    }
  }, []);

  return (
    <Layout>
      {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <Bars
              height="60"
              width="60"
              color="#17496D"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      )}
      <Stack width={'100%'} px={2} alignItems={'center'} py={5}>
        <Heading
          color={'#17496D'}
          fontSize={{ base: '1.3rem', md: '1.8rem', lg: '2rem' }}
          fontWeight={'700'}
          textAlign={'center'}
        >
          Digital Subscription
        </Heading>
        <Stack
          bgColor={'white'}
          border={'1px solid #DBDBDB'}
          borderRadius={'4px'}
          boxShadow={'xl'}
          width={{ base: '100%', md: '60%', lg: '50%', xl: '40%' }}
          mt={4}
          gap={0}
          lineHeight={{ base: '2.1rem', lg: '3rem' }}
        >
          <Text
            color={'#17496D'}
            fontSize={{ base: '1.1rem', md: '1.2rem', lg: '1.3rem' }}
            fontWeight={'500'}
            textDecoration={'underline'}
            textAlign={'center'}
            mt={5}
          >
            Annual
          </Text>
          <Text
            color={'#17496D'}
            fontSize={{ base: '2rem', md: '2rem', lg: '3rem' }}
            fontWeight={'700'}
            textDecoration={'line-through'}
            textAlign={'center'}
            mt={4}
          >
            $100
          </Text>
          <Text
            color={'#FF6217'}
            fontSize={{ base: '1rem', md: '1.1rem', lg: '1rem' }}
            fontWeight={'700'}
            textAlign={'center'}
          >
            Free for 3 month
          </Text>
          <Text
            color={'#343434'}
            fontSize={{ base: '0.80rem', md: '0.90rem', lg: '0.95rem' }}
            fontWeight={'600'}
            textAlign={'center'}
            lineHeight={{ base: '20px', md: '26px' }}
            px={{ base: '4%', md: '5%', lg: '8%', xl: '10%' }}
          >
            Full access to Canadian HR Reporter digital content Regular
            e-newsletter twice a week
          </Text>
          {/* <PaymentForm/> */}
          {userData && userData.hasPaid === true ? (
            <Button
              _hover={{}}
              _focusVisible={{}}
              _active={{}}
              _focus={{}}
              cursor={'default'}
              fontWeight={'500'}
              fontSize={{ base: '1rem', xl: '1.05rem' }}
              mx={'12%'}
              my={4}
              colorScheme="green"
            >
              Subscribed
            </Button>
          ) : (
            <Button
              bgGradient="linear(15.46deg, #17496D 26.3%, #418DB8 86.4%)"
              borderRadius={'5px'}
              color={'white'}
              bgColor={'#418DB8'}
              fontWeight={'500'}
              fontSize={{ base: '1rem', xl: '1.05rem' }}
              _hover={{
                color: 'white',
                bgColor: '#418DB8',
              }}
              mx={'12%'}
              my={4}
              isLoading={loading}
              onClick={e => {
                !userData ? nav('/login') : handleSubmit(e);
                !userData && localStorage.setItem('isSubscribePage', true);
              }}
            >
              Subscribe Now
            </Button>
          )}

          <Divider
            alignSelf={'center'}
            my={1}
            w={'90%'}
            border={'0.5px solid #17496D'}
          />
          <Stack>
            <HStack mx={5} alignItems={'baseline'} my={1}>
              <Text w={5}>
                <Img src={dot} />
              </Text>
              <Text
                color={'rgba(0, 0, 0, 0.7)'}
                fontSize={{ base: '0.80rem', md: '0.90rem', lg: '0.95rem' }}
                fontWeight={'700'}
                lineHeight={{ base: '20px', md: '21px' }}
              >
                A free half-hour consultation with a trusted employment lawyer
              </Text>
            </HStack>
            <Divider
              alignSelf={'center'}
              w={'90%'}
              borderColor={'rgba(0, 0, 0, 0.7)'}
            />
            <HStack mx={5} alignItems={'baseline'} my={1}>
              <Text w={5}>
                <Img src={dot} />
              </Text>
              <Text
                color={'rgba(0, 0, 0, 0.7)'}
                fontSize={{ base: '0.80rem', md: '0.90rem', lg: '0.95rem' }}
                fontWeight={'500'}
                lineHeight={{ base: '20px', md: '21px' }}
              >
                Free monthly webinars on timely HR-related topics (such as
                employment law and employee benefits)
              </Text>
            </HStack>
            <Divider
              alignSelf={'center'}
              w={'90%'}
              borderColor={'rgba(0, 0, 0, 0.7)'}
            />
            <HStack mx={5} alignItems={'baseline'} my={1}>
              <Text w={5}>
                <Img src={dot} />
              </Text>
              <Text
                color={'rgba(0, 0, 0, 0.7)'}
                fontSize={{ base: '0.80rem', md: '0.90rem', lg: '0.95rem' }}
                fontWeight={'500'}
                lineHeight={{ base: '20px', md: '21px' }}
              >
                Early access to our Special Reports (including Best Places to
                Work and the Readers’ Choice Awards)
              </Text>
            </HStack>
            <Divider
              alignSelf={'center'}
              w={'90%'}
              borderColor={'rgba(0, 0, 0, 0.7)'}
            />
            <HStack mx={5} alignItems={'baseline'} my={1}>
              <Text w={5}>
                <Img src={dot} />
              </Text>
              <Text
                color={'rgba(0, 0, 0, 0.7)'}
                fontSize={{ base: '0.80rem', md: '0.90rem', lg: '0.95rem' }}
                fontWeight={'500'}
                lineHeight={{ base: '20px', md: '21px' }}
              >
                Free access to an extensive archive of updated employment law
                decisions
              </Text>
            </HStack>
            <Divider
              alignSelf={'center'}
              w={'90%'}
              borderColor={'rgba(0, 0, 0, 0.7)'}
            />
            <HStack mx={5} alignItems={'baseline'} my={1}>
              <Text w={5}>
                <Img src={dot} />
              </Text>
              <Text
                color={'rgba(0, 0, 0, 0.7)'}
                fontSize={{ base: '0.80rem', md: '0.90rem', lg: '0.95rem' }}
                fontWeight={'500'}
                lineHeight={{ base: '20px', md: '21px' }}
              >
                Free access to an extensive archive of updated collective
                agreements
              </Text>
            </HStack>
            <Divider
              alignSelf={'center'}
              w={'90%'}
              borderColor={'rgba(0, 0, 0, 0.7)'}
            />
            <HStack mx={5} alignItems={'baseline'} my={1}>
              <Text w={5}>
                <Img src={dot} />
              </Text>
              <Text
                color={'rgba(0, 0, 0, 0.7)'}
                fontSize={{ base: '0.80rem', md: '0.90rem', lg: '0.95rem' }}
                fontWeight={'500'}
                lineHeight={{ base: '20px', md: '21px' }}
              >
                Unlimited website access including a searchable archive of
                thousands of articles, stories and features
              </Text>
            </HStack>
            <Divider
              alignSelf={'center'}
              w={'90%'}
              borderColor={'rgba(0, 0, 0, 0.7)'}
            />
            <HStack mx={5} alignItems={'baseline'} my={1}>
              <Text w={5}>
                <Img src={dot} />
              </Text>
              <Text
                color={'rgba(0, 0, 0, 0.7)'}
                fontSize={{ base: '0.80rem', md: '0.90rem', lg: '0.95rem' }}
                fontWeight={'500'}
                lineHeight={{ base: '20px', md: '21px' }}
                mb={4}
              >
                Unlimited access to multimedia resources including videos, white
                papers, podcasts, opinion, surveys and reports
              </Text>
            </HStack>
          </Stack>
        </Stack>
      </Stack>
      {/* payment modal */}
      <Modal isCentered size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Please add your card details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              {isOpen && (
                <>
                  <Stripe amount={'30$'} />
                </>
              )}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Layout>
  );
};

export default DigitalSubscription;
