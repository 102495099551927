import React from 'react';
import {
  Stack,
  HStack,
  Text,
  Link,
  Button,
  Heading,
  InputGroup,
  InputRightElement,
  Input,
  Image,
  Img,
} from '@chakra-ui/react';
import send from '../../assets/images/send1.png';
import twitter from '../../assets/images/twitter.png';
import sendBtn from '../../assets/images/sendBtn.png';
import Linkedin from '../../assets/images/Linkedin.png';
import youtube from '../../assets/images/Shape.svg';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const nav = useNavigate();

  return (
    <Stack
      width={'100%'}
      pt={'50px'}
      bgGradient="linear(15.46deg, #17496D 26.3%, #418DB8 86.4%)"
      justifyContent={'center'}
    >
      <Stack
        direction={{ base: 'column', md: 'row' }}
        justifyContent={'center'}
        alignItems={'center'}
        gap={5}
      >
        <Text color={'white'} fontSize={'18px'} fontWeight={'400'}>
          Subscribe to our Newsletter
        </Text>
        <InputGroup size="lg" width={{ base: '70%', md: '25%' }}>
          <Input
            borderRadius={'full'}
            bgColor={'white'}
            type="email"
            // py={'35px'}
            // h={20}
            placeholder="Your email"
            fontSize={'sm'}
          />
          <InputRightElement mr={1}>
            <Image cursor={'pointer'} w={10} src={sendBtn} />
          </InputRightElement>
        </InputGroup>
      </Stack>

      <Heading
        alignSelf={'center'}
        color={'#FFFFFF'}
        fontSize={'24px'}
        fontWeight={'700'}
        mt={'30px'}
        className="heading"
      >
        Useful HR link
      </Heading>

      <Stack
        direction={{ base: 'column', md: 'row' }}
        gap={{ base: '20px', xl: '70px' }}
        justifyContent={'center'}
        alignItems={'center'}
        mt={4}
      >
        <Link
          onClick={() => nav('/')}
          fontWeight={'400'}
          fontSize={{ base: '13px', xl: '14px' }}
          color={'white'}
          _hover={{
            textDecoration: 'none',
            color: 'white',
          }}
        >
          Home
        </Link>
        <Link
          onClick={() => window.open('https://hr.snsa2a.ai/', '_blank')}
          fontWeight={'400'}
          fontSize={{ base: '13px', xl: '14px' }}
          color={'white'}
          _hover={{
            textDecoration: 'none',
            color: 'white',
          }}
        >
          SnSHRhub
        </Link>
        <Link
          onClick={() =>
            window.open('http://www.aisns.ca/', '_blank')
          }
          fontWeight={'400'}
          fontSize={{ base: '13px', xl: '14px' }}
          color={'white'}
          _hover={{
            textDecoration: 'none',
            color: 'white',
          }}
        >
          S&S
        </Link>
        <Link
          onClick={() => {
            nav('/');
            setTimeout(() => {
              document
                .getElementById('employment-law')
                .scrollIntoView({ behavior: 'smooth' });
            }, 400);
          }}
          fontWeight={'400'}
          fontSize={{ base: '13px', xl: '14px' }}
          color={'white'}
          _hover={{
            textDecoration: 'none',
            color: 'white',
          }}
        >
          Employment Law
        </Link>
        <Link
          fontWeight={'400'}
          fontSize={{ base: '13px', xl: '14px' }}
          color={'white'}
          _hover={{
            textDecoration: 'none',
            color: 'white',
          }}
        >
          Advisory Board
        </Link>
      </Stack>
      <HStack gap={6} justifyContent={'center'} my={5}>
        <Link href="https://www.facebook.com/">
          <Img src={Linkedin} />
        </Link>
        <Link href="https://twitter.com/">
          <Img src={twitter} />
        </Link>
        <Link href="https://www.youtube.com/">
          <Img src={youtube} />
        </Link>
      </HStack>
      <Text
        color={'white'}
        fontSize={'12px'}
        fontWeight={'400'}
        alignSelf={'center'}
        mb={4}
      >
        © Copyright 2023 - hraiGPT
      </Text>
    </Stack>
  );
};

export default Footer;
