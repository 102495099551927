import React from 'react';
import { HStack, Heading, Img, Spacer, Stack, Text } from '@chakra-ui/react';
import arrow from '../../assets/images/Vector.svg';
import pic1 from '../../assets/images/advisory1.jpg';
import pic2 from '../../assets/images/visionary.jpg';

const HrVisionaries = ({ blogsData }) => {
  console.log(
    '🚀 ~ file: HRVisionaries.js:8 ~ HrVisionaries ~ blogsData:',
    blogsData
  );

  function extractYouTubeVideoId(url) {
    // Ensure that url is not undefined or null
    if (!url) {
      return null;
    }

    // Regular expression to match various YouTube URL formats
    const regex =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);

    // If there's a match, return the video ID, otherwise, return null
    return match ? match[1] : null;
  }

  return (
    <Stack width={'100%'} px={{ base: 3, md: 6, lg: 12, xl: 20 }} py={6}>
      <Heading
        mb={6}
        color={'#17496D'}
        fontSize={{ base: '1rem', md: '1.8rem', lg: '2rem' }}
        fontWeight={'700'}
        className="heading"
      >
        HR Visionary
      </Heading>

      <Stack
        alignItems={'center'}
        direction={{ base: 'column', md: 'row' }}
        spacing={{base:8,lg:12,xl:24}}
      >
        <Stack w={{ base: '100%', md: '50%' }}>
          <div
            style={{ borderRadius: '5px', overflow: 'hidden', height: '50vh' }}
          >
            <iframe
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${extractYouTubeVideoId(
                blogsData && blogsData[0]?.mainContent
              )}`}
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </Stack>
        <Stack spacing={4} w={{ base: '100%', md: '50%' }}>
          <Heading
            color={'#000000'}
            className="heading"
            fontSize={{ base: '1rem', md: '1.3rem', lg: '1.5rem' }}
            fontWeight={'700'}
            lineHeight={'35px'}
          >
            {blogsData && blogsData[0]?.blog_intro ||
              'Changing HR for Modern Success with Dave Ulrich'}
          </Heading>
          <Text
            color={'#343434'}
            fontSize={{ base: '0.75rem', md: '1.1rem', lg: '1rem' }}
            fontWeight={'500'}
          >
            {blogsData && blogsData[0]?.description ||
              `Join host Jess Larsen, co-host Gautam Sinham, and Dave Ulrich in
              this engaging conversation as Dave shares his insights on HR
              transformation. Learn how HR professionals can adapt to changing
              landscapes and become strategic business partners. Dave's practical
              advice and visionary thinking will help you chart a course for a
              brighter HR future.`}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default HrVisionaries;
