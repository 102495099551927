import React, { useState } from 'react';
import {
  Box,
  Flex,
  Image,
  Heading,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Link,
  Stack,
  Img,
  Checkbox,
  useToast,
} from '@chakra-ui/react';
import { EmailIcon, LockIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import loginImage from '../../assets/images/login.png';
import logo from '../../assets/images/logowhite.png';
import { useNavigate, useParams } from 'react-router-dom';
import {
  forgotPasswordService,
  resetPasswordService,
} from '../../services/Service';
const ChangePassword = () => {
  const nav = useNavigate();
  const toast = useToast();
  const { token } = useParams();
  console.log(
    '🚀 ~ file: ChangePassword.js:24 ~ ChangePassword ~ token:',
    token
  );
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setloading] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [submitClicked, setSubmitClicked] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordChange = event => {
    const newPassword = event.target.value;
    setConfirmPassword(newPassword);
  };
  const handlePasswordChange = event => {
    const newPassword = event.target.value;
    setPassword(newPassword);
  };

  const handleSubmit = async event => {
    event.preventDefault();

    setSubmitClicked(true);

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    const isValidPassword = passwordRegex.test(password);
    const isvalidConfirmPassword = confirmPassword && confirmPassword === password
    if (!confirmPassword) {
      setConfirmPasswordError('Confirm Password is required.');
    } else if (confirmPassword !== password) {
      setConfirmPasswordError('Passwords do not match.');
    } else {
      setConfirmPasswordError('');
    }

    if (!isValidPassword) {
      setPasswordError(
        'Password must contain at least 8 characters, one uppercase, one lowercase, and one digit.'
      );
    } else {
      setPasswordError('');
    }

    if (isvalidConfirmPassword && isValidPassword) {
      // Handle successful form submission
      setloading(true);
      console.log('Form submitted successfully.');
      const res = await resetPasswordService(password, token);
      if (res.success) {
        setloading(false);
        toast({
          title: res.message,
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
        nav('/');
        setPassword('');
        setConfirmPassword('');
      } else {
        setloading(false);
        toast({
          title: res.message,
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } else {
      console.log('Form has errors. Please correct them.');
    }
  };

  const isSubmitDisabled = !confirmPassword || !password;

  return (
    <Stack gap={0} overflow={'hidden'} maxWidth={'100%'} alignItems={'center'}>
      <Stack
        w="100%"
        justifyContent={'center'}
        direction="row"
        bg={
          'var(--gradient, linear-gradient(15deg, #17496D 0%, #418DB8 92.71%))'
        }
        height={'7vh'}
        alignItems={'center'}
        boxShadow={'0px 8px 30px 0px rgba(13, 24, 33, 0.08)'}
      >
        <Img w={6} src={logo} />
        <Text fontSize={'lg'} color="white">
          SnSHRhub
        </Text>
      </Stack>
      <Stack
        height={'93vh'}
        direction="row"
        justifyContent={'center'}
        alignItems={'center'}
        width="100vw"
        bg="#F8FBFF"
      >
        <Flex
          h='full'
          px={{base:6,sm:8,md:12,lg:24}}
          height={'100%'}
          width={{base:'100%',md:'60%',lg:'50%'}}
          textAlign={'left'}
          align="center"
        >
          <Stack width="100%" spacing="6">
            <Stack textAlign={{base:'start',md:'center'}}>
              <Text fontWeight={'600'} fontSize={'xl'} color={'#0D1821'}>
                Reset Password
              </Text>
              <Text>
                Create your new password for SnSHRhub so you can login to
                your account
              </Text>
            </Stack>
            <form style={{ width: '100%' }} onSubmit={handleSubmit}>
              <Stack spacing={6}>
                <Stack spacing={2}>
                  <Text fontWeight={'600'} fontSize={'sm'}>
                    New Password
                  </Text>
                  <InputGroup>
                    <Input
                      borderRadius={'full'}
                      bg="white"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <InputRightElement cursor={'pointer'}>
                      {showPassword ? (
                        <ViewOffIcon
                          color="gray.300"
                          onClick={handlePasswordVisibility}
                        />
                      ) : (
                        <ViewIcon
                          color="gray.300"
                          onClick={handlePasswordVisibility}
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                  {passwordError && (
                    <Text color="red" fontSize={'sm'}>
                      {passwordError}
                    </Text>
                  )}
                </Stack>
                <Stack spacing={2}>
                  <Text fontWeight={'600'} fontSize={'sm'}>
                    Confirm New Password
                  </Text>
                  <InputGroup>
                    <Input
                      borderRadius={'full'}
                      bg="white"
                      type={showConfirmPassword ? 'text' : 'password'}
                      placeholder="Password"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />
                    <InputRightElement cursor={'pointer'}>
                      {showConfirmPassword ? (
                        <ViewOffIcon
                          color="gray.300"
                          onClick={() =>
                            setshowConfirmPassword(!showConfirmPassword)
                          }
                        />
                      ) : (
                        <ViewIcon
                          color="gray.300"
                          onClick={() =>
                            setshowConfirmPassword(!showConfirmPassword)
                          }
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                  {confirmPasswordError && (
                    <Text color="red" fontSize={'sm'}>
                      {confirmPasswordError}
                    </Text>
                  )}
                </Stack>
                <Button
                  isLoading={loading}
                  w="100%"
                  fontWeight={'500'}
                  color={'white'}
                  borderRadius={'full'}
                  bg="var(--gradient, linear-gradient(15deg, #17496D 0%, #418DB8 92.71%))"
                  _hover={{}}
                  type="submit"
                  isDisabled={isSubmitDisabled}
                >
                  Save Password
                </Button>
              </Stack>
            </form>
          </Stack>
        </Flex>
      </Stack>
    </Stack>
  );
};

export default ChangePassword;
