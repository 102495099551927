import React from 'react';
import { ChakraProvider, theme } from '@chakra-ui/react';
import MainRoutes from './utils/routes/MainRoutes';
import './App.css';
import ChatWidget from './components/home/ChatWidget';
import { BlogsContext } from './context/BlogsContext';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BlogsContext>
        <MainRoutes />
        {/* Chat widget */}
        <ChatWidget />
      </BlogsContext>
    </ChakraProvider>
  );
}

export default App;
