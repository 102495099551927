import React, { createContext, useEffect, useState } from 'react';
import { getBlogsByTypeService } from '../services/Service';

const BlogsDataContext = createContext();
export function BlogsContext({ children }) {
  const [blogs, setBlogs] = useState();
  const [techTrendsBlogs, setTechTrendsBlogs] = useState();
  const [visionaryBlogs, setVisionaryBlogs] = useState();
  const [employmentLawBlogs, setEmploymentLawBlogs] = useState();
  const [resources, setResources] = useState();

  // get all moments functions
  const getBlogsByType = async () => {
    try {
      const res = await getBlogsByTypeService();
      console.log('🚀 ~ file: BlogsContext.js:13 ~ getBlogsByType ~ res:', res);
      if (res.success) {
        setBlogs(res.data);
        setTechTrendsBlogs(res.data.hrTechTrends)
        setEmploymentLawBlogs(res.data.employmentLawNews)
        setVisionaryBlogs(res.data.hrVisionary)
        setResources(res.data.hrResources)
      }
      return res
    } catch (error) {
      console.log('error', error);
      //   setIsLoading(false);
    }
  };

  return (
    <BlogsDataContext.Provider
      value={{
        getBlogsByType,
        blogs,
        employmentLawBlogs,
        techTrendsBlogs,
        visionaryBlogs,
        resources
      }}
    >
      {children}
    </BlogsDataContext.Provider>
  );
}

export default BlogsDataContext;
