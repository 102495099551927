import React from 'react';
import Layout from '../../layout/Layout';
import { Button, Heading, Image, Img, Stack, Text } from '@chakra-ui/react';
import hrtransformation from '../../../assets/images/hrtransformation.jpg';
import contactus from '../../../assets/images/contactus.svg';
import { useNavigate } from 'react-router-dom';

const HrDigitalTranformation = () => {
  const text = `
  Step into the future of Human Resources with snsHRhub's cutting-edge HR Digital Transformation Services.
  At snsHRhub, we redefine the way organizations manage their workforce, seamlessly integrating technology to enhance efficiency, streamline processes, and elevate the overall employee experience.
  Our comprehensive suite of services is meticulously designed to propel your HR practices into the digital age, fostering innovation and excellence.
  Join us on a transformative journey where every solution is crafted with precision, and every service is geared towards revolutionizing your HR landscape.
  Digital Transformation Services:
  HR Technology Integration:
  Implementing and integrating advanced HR technologies such as Human Capital Management (HCM) systems, Applicant Tracking Systems (ATS), and Employee Self-Service Portals.
  Data Analytics and Insights:
  Leveraging data analytics tools to gather actionable insights into workforce trends, performance metrics, and employee engagement.
  Automation of HR Processes:
  Implementing robotic process automation (RPA) to streamline repetitive HR tasks, such as payroll processing, benefits administration, and onboarding.
  Employee Experience Platforms:
  Introducing platforms that enhance the overall employee experience, including tools for employee feedback, recognition, and collaboration.
  AI and Machine Learning Applications:
  Integrating artificial intelligence (AI) and machine learning (ML) algorithms for talent acquisition, employee performance analysis, and predictive analytics.
  Digital Learning and Development:
  Implementing e-learning platforms, virtual training programs, and digital learning tools to enhance employee skills and development.
  `;
  // Mobile HR Solutions:
  // Developing mobile applications for HR functions, enabling employees to access HR services, submit requests, and receive information on-the-go.
  // Cloud-Based HR Systems:
  // Transitioning to cloud-based HR systems for increased scalability, accessibility, and data security.
  // Digital Employee Onboarding:
  // Implementing digital onboarding processes to streamline new employee orientation and paperwork.
  // Workforce Analytics:
  // Utilizing workforce analytics to gain insights into productivity, performance, and talent retention.
  // Chatbots and Virtual Assistants:
  // Integrating chatbots and virtual assistants to provide instant support for HR-related queries and assistance.
  // Electronic Employee Files:
  // Digitizing and managing employee records electronically for efficient and secure record-keeping.
  const nav = useNavigate();
  return (
    <Layout>
      <Stack width={'100%'} px={{ base: 3, md: 6, lg: 12, xl: 20 }} py={6}>
        <Heading
          color={'#17496D'}
          fontSize={{ base: '1rem', md: '1.8rem', lg: '2rem' }}
          fontWeight={'700'}
          className="heading"
          mb={8}
        >
          HR Services
        </Heading>
        <Stack
          gap={{base:12,md:10,lg:12,xl:'7vw'}}
          direction={{ base: 'column', md: 'row' }}
        >
          <Img
            w={{ base: '100%', md: '50%' }}
            h={{ base: '40vh', md: '50vh', lg: '60vh' }}
            src={hrtransformation}
            borderRadius={'5px'}
          />
          <Stack>
            {/* <Text fontWeight={'600'} fontSize={'15px'} color="#FF6217">
              HR Digital Transformation Services
            </Text> */}
            <Heading
              color={'#17496D'}
              fontSize={{ base: '1rem', md: '1.8rem', lg: '1.8rem' }}
              fontWeight={'700'}
              className="heading"
              mt={2}
            >
              HR Digital Transformation Services
            </Heading>
            <Text
              color={'#343434'}
              fontSize={{ base: '0.75rem', md: '0.85rem', lg: '0.95rem' }}
              fontWeight={'500'}
              marginBottom={'0'}
              whiteSpace="pre-line"
              lineHeight={{ base: '23px', lg: '27px' }}
            >
              {text}
            </Text>
            <Button
              mt={3}
              onClick={() => nav('/contact-us')}
              size={{ base: 'sm', md: 'lg' }}
              leftIcon={<Image src={contactus} />}
              color="white"
              _hover={{}}
              bg="linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)"
              boxShadow={'0px 2.25px 9px 0px rgba(74, 58, 255, 0.18)'}
              borderRadius={'5px'}
              fontWeight={'400'}
              w="fit-content"
            >
              Work with us
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default HrDigitalTranformation;
