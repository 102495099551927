import {
  Flex,
  Img,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { MdChatBubbleOutline } from 'react-icons/md';
import chatLogo from '../../assets/images/chatLogo.svg';
import filledLogo from '../../assets/images/filledLogo.svg';
import online from '../../assets/images/online.svg';
import { IoMdSend } from 'react-icons/io';
import { SNSChatBotService } from '../../services/Service';
import { ThreeDots } from 'react-loader-spinner';
const ChatWidget = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  console.log('🚀 ~ file: ChatWidget.js:20 ~ ChatWidget ~ messages:', messages);
  const [newMessage, setNewMessage] = useState('');
  const [loadingIndexes, setLoadingIndexes] = useState([]);

  const faqRef = useRef();

  const ScrollToBottom = () => {
    if (faqRef.current) {
      faqRef.current.scrollTop = faqRef.current.scrollHeight;
    }
  };
  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const handleSubmit = async () => {
    if (newMessage.trim() === '') {
      return; // Don't submit empty messages
    }

    // Add a new message to the array
    setMessages(prevMessages => [
      ...prevMessages,
      {
        query: newMessage,
        answer: '',
      },
    ]);
    setNewMessage('');

    try {
      // Make API call to get the answer
      const response = await SNSChatBotService(newMessage);
      const answer = response.text;

      // Use the updatedMessages in the useEffect to ensure it's updated
      setMessages(prevMessages => {
        const lastMessageIndex = prevMessages.length - 1;

        // Update the answer of the specific message in the copy of the array
        const updatedMessages = [...prevMessages];
        updatedMessages[lastMessageIndex].answer = answer;

        return updatedMessages;
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      // Remove the loading state for the current message
      setLoadingIndexes(prevLoadingIndexes => prevLoadingIndexes.slice(0, -1));
    }

    // Clear the input field
  };

  // Add useEffect to handle side effects after state update
  useEffect(() => {
    // Scroll to bottom after messages state has been updated
    ScrollToBottom();
  }, [messages]);

  const handleInputChange = event => {
    setNewMessage(event.target.value);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className="main-container">
      {/* Chat icon */}
      <div className="chat-icon" onClick={toggleChat}>
        <MdChatBubbleOutline color="white" fontSize={'20px'} />
      </div>

      {/* Chat window */}
      {isChatOpen && (
        <div className="chat-window">
          <Stack
            borderTopRadius={'20px'}
            px={6}
            py={3}
            direction="row"
            bg="#17496D"
            w="100%"
            spacing={4}
            alignItems={'center'}
          >
            <Img w={12} src={chatLogo} />
            <Stack fontSize="sm" spacing={0}>
              <Text fontWeight={'500'} marginBottom={0} color="white">
                SnS Bot
              </Text>
              <Flex alignItems={'center'} gap={1}>
                <Img src={online} />
                <Text color="#E9E9E9">online</Text>
              </Flex>
            </Stack>
          </Stack>
          <Stack
            ref={faqRef}
            spacing={4}
            px={6}
            py={4}
            className="message-container"
          >
            {messages?.map(message => (
              <Stack>
                {/* question */}
                <Stack spacing={8} direction={'row'}>
                  <Img w={12} h={12} src={filledLogo} />
                  <Text className="bot-message">{message?.query}</Text>
                </Stack>
                {/* answer */}
                <Stack pt={2} alignItems={'end'}>
                  {message?.answer === '' ? (
                    <ThreeDots
                      height="50"
                      width="50"
                      radius="9"
                      color="#17496D"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  ) : (
                    <Text className="user-message">{message?.answer}</Text>
                  )}
                </Stack>
              </Stack>
            ))}
          </Stack>
          <Stack px={6} py={4}>
            <InputGroup bg="transparent" w="100%">
              <InputRightElement pointerEvents="none">
                <IoMdSend color="#999999" />
              </InputRightElement>
              <Input
                variant="unstyled"
                type="tel"
                placeholder="Ask your question..."
                value={newMessage}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
            </InputGroup>
          </Stack>
        </div>
      )}
    </div>
  );
};

export default ChatWidget;
