import React from 'react';
import { HStack, Heading, Img, Spacer, Stack, Text } from '@chakra-ui/react';
import arrow from '../../assets/images/Vector.svg';
import background from '../../assets/images/Rectangle 1.jpg';
import { FaPlay } from 'react-icons/fa';

const DigitalTransformationServices = () => {
  return (
    <Stack width={'100%'} px={{ base: 3, md: 6, lg: 12, xl: 20 }} py={6}>
      <HStack mb={6}>
        <Heading
          color={'#17496D'}
          fontSize={{ base: '1rem', md: '1.8rem', lg: '2rem' }}
          fontWeight={'700'}
          className="heading"
        >
          HR Digital Transformation Services
        </Heading>
        <Spacer />
        {/* <HStack minW={'fit-content'} gap={{ base: 2, md: 4 }}>
          <Text
            color={'#17496D'}
            fontSize={{ base: '0.9rem', md: '1.2rem', lg: '1.3rem' }}
            fontWeight={'500'}
            cursor={'pointer'}
          >
            See all
          </Text>
          <Img src={arrow} w={{ base: 1.5, md: 2 }} />
        </HStack> */}
      </HStack>
      <Stack
        spacing={{ base: 6 }}
        w="100%"
        direction={{ base: 'column', md: 'column' }}
        alignItems={'center'}
      >
        <Stack
          bgImage={background}
          h={{ base: '40vh', md: '70vh', xl: '80vh' }}
          bgRepeat={'no-repeat'}
          bgPosition={'center'}
          bgSize={'cover'}
          borderRadius={'5px'}
          justifyContent={'center'}
          alignItems={'center'}
          w={{ base: '100%', md: '100%' }}
        ></Stack>
        <Stack w={{ base: '100%', md: '100%' }}>
          <Heading
            className="heading"
            color={'#000000'}
            fontSize={{ base: '1rem', md: '1.3rem', lg: '1.3rem',xl:'1.5rem' }}
            fontWeight={'700'}
          >
            Welcome to SnsHrHub
          </Heading>
          <Text
            color={'#343434'}
            fontSize={{ base: '0.75rem', md: '1.1rem', lg: '1rem'}}
            fontWeight={'500'}
            lineHeight={{ base: '20px', md: '25px', lg: '25px',xl:'30px' }}
          >
            Welcome to snsHRhub, where we lead the future of HR with
            cutting-edge Digital Transformation Services. Join us on a
            transformative journey to redefine your HR landscape, leveraging
            advanced technologies for seamless workforce management and elevated
            employee experiences.
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DigitalTransformationServices;
