import React, { useContext, useEffect, useState } from 'react';
import Layout from '../layout/Layout';
import HRTechTrends from './HRTechTrends';
import EmploymentLaw from './EmploymentLaw';
import DigitalTransformationServices from './DigitalTransformationServices';
import HrServices from './HrServices';
import HrVisionaries from './HRVisionaries';
import BlogsDataContext from '../../context/BlogsContext';
import { Bars } from 'react-loader-spinner';

const Home = () => {
  const [loading, setloading] = useState();
  const {
    blogs,
    employmentLawBlogs,
    techTrendsBlogs,
    visionaryBlogs,
    getBlogsByType,
  } = useContext(BlogsDataContext);
  console.log('🚀 ~ file: Home.js:12 ~ Home ~ blogs:', blogs);

  useEffect(() => {
    setloading(true)
    const getBlogs = async () => {
      const res = await getBlogsByType();
      setloading(false)
    };
    getBlogs();
  }, []);

  return (
    <Layout>
       {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <Bars
              height="60"
              width="60"
              color="#17496D"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      )}
      <DigitalTransformationServices />
      <HrServices />
      <HrVisionaries blogsData={visionaryBlogs} />
      <HRTechTrends blogsData={techTrendsBlogs} />
      <EmploymentLaw blogsData={employmentLawBlogs} />
    </Layout>
  );
};

export default Home;
