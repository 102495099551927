import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, useToast } from '@chakra-ui/react';
import ErrorToaster from '../../utils/toaster/error/ErrorToaster';
import SuccessToaster from '../../utils/toaster/success/SuccessToaster';

const Checkout = ({ amount }) => {
  const toast = useToast();
  const stripe = useStripe();
  const elements = useElements();
  const [isSuccess, setisSuccess] = useState(false);

  const productsData = [
    {
      quantity: 2,
      _id: 'sd56723by723b4723',
    },
  ];

  const [loading, setloading] = useState(false);

  const CARD_ELEMENT_OPTIONS = {
    iconStyle: 'solid',
    hidePostalCode: true,
    style: {
      base: {
        iconColor: 'rgb(240, 57, 122)',
        color: 'rgb(240, 57, 122)',
        fontSize: '16px',
        fontFamily: '"Open Sans", sans-serif',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#CFD7DF',
        },
      },
      invalid: {
        color: '#e5424d',
        ':focus': {
          color: '#303238',
        },
      },
    },
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setloading(true);
    console.log('paynow clicked');
    // if (!stripe || !elements) {
    //   return;
    // }

    // if (!amount) {
    //   ErrorToaster(toast, 'Error in fetching bill');
    //   return;
    // }
    //
    // try {
    stripe
      .confirmPayment({
        elements,
      })
      .then(res => {
        console.log('🚀 ~ file: Checkout.js:63 ~ handleSubmit ~ res:', res);
      }).catch((error) => {
        console.log("🚀 ~ file: Checkout.js:67 ~ handleSubmit ~ error:", error)
      })

    // if (error) {
    //   setloading(false);
    // } else if (paymentIntent && paymentIntent.status === 'succeeded') {
    //   SuccessToaster(toast, 'Payment is successfully done');
    // } else {
    //   setloading(false);
    // }
    // }
    // catch (error) {
    //   setloading(false);
    // }
    // const card = elements.getElement(CardElement);
    // console.log('🚀 ~ file: Checkout.js ~ line 62 ~ Checkout ~ card', card);

    // try {
    //   setloading(true);

    //   // const { token } = await stripe.createToken(card);
    //   // console.log('🚀 ~ file: Checkout.js ~ line 67 ~ Checkout ~ token', token);

    //   // const payload = {
    //   //   delivery_address: ShippingDetails.addressval,
    //   //   email: ShippingDetails.emailval,
    //   //   token: token.id,
    //   //   amount: amount,
    //   //   currency: 'USD',
    //   //   description: ShippingDetails?.commentval,
    //   //   product_ids: productsData,
    //   // };

    //   // const { data } = await axios.post(
    //   //   '/payment/paymentProcessStripe',
    //   //   payload
    //   // );
    //   // setsuccessData({
    //   //   transactionId: data.transactionId,
    //   //   email: payload.email,
    //   // });
    //   // SuccessToaster(toast, 'Transaction Id :' + data.transactionId);
    //   // setisSuccess(true);
    //   setloading(false);
    // } catch (error) {
    //   setloading(false);
    //   // ErrorToaster(toast, error?.response?.data?.message || error?.message);
    //   // setisSuccess(false);
    // }
  };

  return (
    <>
      {/* {
                isSuccess && <ShippingModal
                    selfOpen={true}
                    transactionId={successData.transactionId}
                    email={successData.email}
                />
            } */}

      <form onSubmit={handleSubmit} style={{ justifyContent: 'center' }}>
        <Box
          alignItems={'center'}
          justifyContent={'center'}
          borderRadius={'lg'}
          boxShadow={'lg'}
          w="full"
          minH={'6vh'}
          p={'2'}
        >
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </Box>
        <Button
          mt={'8'}
          width={'full'}
          backgroundColor={'#368CCD'}
          height={'40px'}
          textAlign={'center'}
          color={'white'}
          size={'md'}
          isLoading={loading}
          type="submit"
          fontSize={'sm'}
          _hover={{}}
        >
          PAY NOW
        </Button>
      </form>
    </>
  );
};

export default Checkout;
