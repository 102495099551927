import React from 'react';
import Layout from '../layout/Layout';
import { Heading, Img, Stack, Text } from '@chakra-ui/react';
import hrtransformation from '../../assets/images/hrtransformation.jpg';

const AboutUs = () => {
  const whoWeAreText = `In the dynamic realm of HR, led by Founder and CEO Samia Saadat, we've been a decade-long trailblazer, committed to modernizing HR and shaping its strategic role. Pioneering innovation in technology, advisory services, and workforce augmentation, we've earned trust with our comprehensive solutions.

  Our vision empowers HR, shifting from traditional to strategic roles. With a focus on excellence, we've integrated AI legal applications for enhanced efficiency and legal compliance, anticipating elevated standards. Our commitment to client success is evident in tangible improvements witnessed by organizations.
  
  Celebrating a decade of achievements, our unwavering commitment drives continuous innovation and positions us to persist in modernizing HR for the future. Guided by her vision, we're a story of resilience, innovation, and client success, eagerly anticipating the next decade of empowering HR as the most valued department.`;
  return (
    <Layout>
      <Stack width={'100%'} px={{ base: 3, md: 6, lg: 12, xl: 20 }} py={6}>
        <Heading
          color={'#17496D'}
          fontSize={{ base: '1rem', md: '1.8rem', lg: '2rem' }}
          fontWeight={'700'}
          className="heading"
          mb={6}
        >
          About Us
        </Heading>
        <Stack
          spacing={{ base: 6, md: 12 }}
          direction={{ base: 'column', md: 'row' }}
          alignItems={{base:'baseline',xl:'center'}}
        >
          <Img
            borderRadius={'5px'}
            h={{ base: '40vh', md: '50vh', lg: '60vh' }}
            w={{ base: '100%', md: '50%' }}
            src={hrtransformation}
          />
          <Stack w={{ base: '100%', md: '50%' }}>
            <Heading
              color={'#17496D'}
              fontSize={{ base: '1rem', md: '1.8rem', lg: '1.8rem' }}
              fontWeight={'700'}
              className="heading"
              mb={3}
            >
              Who We Are?
            </Heading>
            <Text
              color={'#343434'}
              fontSize={{ base: '0.75rem', md: '0.85rem', lg: '0.95rem' }}
              fontWeight={'500'}
              marginBottom={'0'}
              whiteSpace={'pre-line'}
            >
              {whoWeAreText}
            </Text>
          </Stack>
        </Stack>
        <Stack
          mt={12}
          spacing={{ base: 6, md: 12 }}
          direction={{ base: 'column', md: 'row' }}
          alignItems={{base:'baseline',xl:'center'}}
        >
          <Stack w={{ base: '100%', md: '50%' }} pt={3}>
            <Heading
              color={'#17496D'}
              fontSize={{ base: '1rem', md: '1.8rem', lg: '1.8rem' }}
              fontWeight={'700'}
              className="heading"
              mb={6}
            >
              Our Mission
            </Heading>
            <Text
              color={'#343434'}
              fontSize={{ base: '0.75rem', md: '0.85rem', lg: '0.95rem' }}
              fontWeight={'500'}
              marginBottom={'0'}
            >
              Empowering organizations through a decade of innovation, we are
              dedicated to modernizing HR practices. Our mission is to transform
              HR departments into strategic pillars, recognizing people as the
              most vital asset within organizations. With a comprehensive suite
              of services, we aim to build resilient foundations for our
              clients, fostering positive and compliant work environments
              efficiently.
            </Text>
            <Heading
              pt={3}
              color={'#17496D'}
              fontSize={{ base: '1rem', md: '1.8rem', lg: '1.8rem' }}
              fontWeight={'700'}
              className="heading"
              mb={6}
            >
              Our Vision
            </Heading>
            <Text
              color={'#343434'}
              fontSize={{ base: '0.75rem', md: '0.85rem', lg: '0.95rem' }}
              fontWeight={'500'}
            >
              Guided by our visionary commitment, we aspire to lead the way in
              HR excellence, shaping the future of organizations across North
              America. Our vision is to continue pioneering innovative
              solutions, staying at the forefront of HR technology, advisory
              services, and workforce augmentation. We envision a future where
              our ongoing commitment to excellence, client success, and the
              integration of cutting-edge AI applications solidify us as the
              most valued partner for businesses navigating the dynamic
              landscape of human resources.
            </Text>
          </Stack>
          <Img
            w={{ base: '100%', md: '50%' }}
            h={{ base: '40vh', md: '50vh', lg: '60vh' }}
            src={hrtransformation}
            borderRadius={'5px'}
          />
        </Stack>
      </Stack>
    </Layout>
  );
};

export default AboutUs;
