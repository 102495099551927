import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  Stack,
  Button,
  Link,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  Img,
  HStack,
  Spacer,
  Tooltip,
} from '@chakra-ui/react';
import { BsList } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/newslogo.svg';
import { CgClose } from 'react-icons/cg';
import './header.css';
const DrawerButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const nav = useNavigate();

  return (
    <Stack
      w={'100%'}
      display={{ base: 'inherit', md: 'inherit', lg: 'none' }}
      alignItems={'end'}
    >
      <Button
        w={'fit-content'}
        ref={btnRef}
        onClick={onOpen}
        borderRadius={'5px'}
        color={'white'}
        bgColor={'#418DB8'}
        px={5}
        _hover={{
          color: 'white',
          bgColor: '#418DB8',
        }}
      >
        <BsList color="white" size={20} />
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader
            py={1}
            bgGradient="linear(15.46deg, #17496D 26.3%, #418DB8 86.4%)"
          >
            <HStack>
              <Img
                w={24}
                cursor="pointer"
                onClick={() => nav('/')}
                src={logo}
              />
              <Spacer />
              <CgClose onClick={onClose} fontSize={20} color="white" />
            </HStack>

            {/* <DrawerCloseButton /> */}
          </DrawerHeader>

          <DrawerBody
            pt={10}
            bgGradient="linear(15.46deg, #17496D 26.3%, #418DB8 86.4%)"
          >
            <Stack gap={{ base: '20px', xl: '40px' }}>
              <Link
                onClick={() => nav('/staff-augmentation')}
                fontWeight={'500'}
                fontSize={{ base: '1rem', xl: '16px' }}
                color={'white'}
                _hover={{
                  textDecoration: 'none',
                  color: 'white',
                }}
              >
                Talent Management
              </Link>
              <Accordion borderColor="transparent" w="100%" allowMultiple>
                <AccordionItem mb={4}>
                  <AccordionButton
                    p={0}
                    _active={{ bg: 'transparent' }}
                    color="white"
                  >
                    <Box as="span" flex="1" textAlign="left">
                      HR Resources
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel px={0} py={4}>
                    <Stack spacing={3}>
                      <Link
                        onClick={() => nav('/hr-policy-template')}
                        fontWeight={'500'}
                        fontSize={{ base: '0.95rem', xl: '16px' }}
                        color={'white'}
                        _hover={{
                          textDecoration: 'none',
                          color: 'white',
                        }}
                      >
                        HR Policy Templates
                      </Link>
                      <Link
                        onClick={() => nav('/hr-process-template')}
                        fontWeight={'500'}
                        fontSize={{ base: '0.95rem', xl: '16px' }}
                        color={'white'}
                        _hover={{
                          textDecoration: 'none',
                          color: 'white',
                        }}
                      >
                        HR Process Template
                      </Link>
                      <Link
                        onClick={() => nav('/toolkits')}
                        fontWeight={'500'}
                        fontSize={{ base: '0.95rem', xl: '16px' }}
                        color={'white'}
                        _hover={{
                          textDecoration: 'none',
                          color: 'white',
                        }}
                      >
                        Toolkits
                      </Link>
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton
                    p={0}
                    _active={{ bg: 'transparent' }}
                    color="white"
                  >
                    <Box as="span" flex="1" textAlign="left">
                      HR Advisory
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel px={0} py={4}>
                    <Stack spacing={3}>
                      <Link
                        fontWeight={'500'}
                        fontSize={{ base: '0.95rem', xl: '16px' }}
                        color={'white'}
                        _hover={{
                          textDecoration: 'none',
                          color: 'white',
                        }}
                        onClick={() => nav('/digital-transformation')}
                      >
                        HR Digital Transformation Services
                      </Link>
                      <Link
                        fontWeight={'500'}
                        fontSize={{ base: '0.95rem', xl: '16px' }}
                        color={'white'}
                        _hover={{
                          textDecoration: 'none',
                          color: 'white',
                        }}
                        onClick={() => nav('/technology-advisory')}
                      >
                        HR Technology Advisory
                      </Link>
                      <Link
                        fontWeight={'500'}
                        fontSize={{ base: '0.95rem', xl: '16px' }}
                        color={'white'}
                        _hover={{
                          textDecoration: 'none',
                          color: 'white',
                        }}
                        onClick={() => nav('/specific-change-management')}
                      >
                        HR Change Management
                      </Link>
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              <Link
                onClick={() => {
                  onClose();
                  nav('/');
                  setTimeout(() => {
                    document
                      .getElementById('hr-tech-trends')
                      .scrollIntoView({ behavior: 'smooth' });
                  }, 400);
                }}
                fontWeight={'500'}
                fontSize={{ base: '1rem', xl: '16px' }}
                color={'white'}
                _hover={{
                  textDecoration: 'none',
                  color: 'white',
                }}
              >
                HR Tech Trends
              </Link>
              <Link
                onClick={() => nav('/about-us')}
                fontWeight={'500'}
                fontSize={{ base: '1rem', xl: '16px' }}
                color={'white'}
                _hover={{
                  textDecoration: 'none',
                  color: 'white',
                }}
              >
                About Us
              </Link>
              <Link
                onClick={() => nav('/contact-us')}
                fontWeight={'500'}
                fontSize={{ base: '13px', xl: '16px' }}
                color={'white'}
                _hover={{
                  textDecoration: 'none',
                  color: 'white',
                }}
              >
                Contact Us
              </Link>

              <Link
                onClick={() => nav('/subscribe')}
                fontWeight={'500'}
                fontSize={{ base: '1rem', xl: '16px' }}
                color={'white'}
                _hover={{
                  textDecoration: 'none',
                  color: 'white',
                }}
              >
                Subscribe
              </Link>

              <Tooltip
                p={3}
                hasArrow
                label="Our Attract to Alumni Legal (A2AL)AI application swiftly answers your questions, providing efficient legal insights."
              >
                <Button
                  borderRadius={'5px'}
                  color={'#FF6217'}
                  bgColor={'white'}
                  fontWeight={'600'}
                  boxShadow={'0px 4px 32px 0px rgba(0, 0, 0, 0.25)'}
                  fontSize={{ base: '13px', xl: '16px' }}
                  px={10}
                  _hover={{}}
                  onClick={() => window.open('https://hr.snsa2a.ai/', '_blank')}
                >
                  A2AL
                </Button>
              </Tooltip>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Stack>
  );
};

export default DrawerButton;
