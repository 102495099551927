import React from 'react';
import Layout from '../../layout/Layout';
import { Button, Heading, Image, Img, Stack, Text } from '@chakra-ui/react';
import hradvisory from '../../../assets/images/hradvisory.jpg';
import contactus from '../../../assets/images/contactus.svg';
import { useNavigate } from 'react-router-dom';

const HrTechnologyAdvisory = () => {
  const nav = useNavigate();
  const text = `Unlock the power of strategic HR advancement with snsHRhub's holistic HR Technology Advisory services. At snsHRhub, we recognize the pivotal role technology plays in reshaping human resources, and our advisory services are meticulously crafted to guide organizations through this dynamic landscape. Whether you're navigating the implementation of new technologies, optimizing existing systems, or seeking innovative solutions, our HR Technology Advisory is your compass for informed decision-making.
  Comprehensive HR Technology Advisory Services:
  HR Technology Assessment:
  Conducting comprehensive evaluations of existing HR technology infrastructure to identify strengths, weaknesses, and opportunities for optimization.
  Strategic Technology Planning:
  Collaborating with organizations to develop tailored roadmaps for the strategic implementation and evolution of HR technology solutions aligned with business objectives.
  Vendor Selection and Management:
  Assisting in the selection of HR technology vendors, ensuring compatibility with organizational needs, and providing ongoing vendor relationship management.
  System Implementation and Integration:
  Overseeing the seamless implementation of new HR technology systems and ensuring integration with existing platforms for a cohesive and efficient digital ecosystem.
  Data Security and Compliance:
  Providing guidance on data security measures and ensuring HR technology systems comply with relevant regulations and industry standards.
  `;
  // User Training and Adoption Programs:
  // Developing and executing training programs to enhance user proficiency and drive successful adoption of new HR technology solutions among employees.
  // Continuous System Optimization:
  // Implementing ongoing reviews and updates to HR technology systems to ensure they remain aligned with evolving business needs and industry best practices.
  // Mobile HR Solutions:
  // Designing and implementing mobile-centric HR solutions to enhance accessibility, user experience, and employee engagement.
  // HR Analytics and Reporting:
  // Implementing advanced analytics tools to derive meaningful insights from HR data, enabling informed decision-making and strategic workforce planning.

  // Technology Roadmap Alignment:
  // Aligning HR technology strategies with broader organizational goals, ensuring technology investments contribute to overall business success.

  // Digital Transformation Advisory:
  // Providing guidance on broader digital transformation initiatives, aligning HR technology with organizational digital strategies for comprehensive business growth.

  // Emerging Technology Exploration:
  // Evaluating and advising on the adoption of emerging technologies such as AI, machine learning, and robotic process automation to enhance HR capabilities.

  // snsHRhub's HR Technology Advisory services are designed to be your partner in navigating the complexities of HR technology, ensuring your organization stays at the forefront of innovation and efficiency in the ever-evolving digital landscape. Welcome to a future where technology transforms HR, and snsHRhub is your guide on this transformative journey.
  return (
    <Layout>
      <Stack width={'100%'} px={{ base: 3, md: 6, lg: 12, xl: 20 }} py={5}>
        <Heading
          color={'#17496D'}
          fontSize={{ base: '1rem', md: '1.8rem', lg: '2rem' }}
          fontWeight={'700'}
          className="heading"
          mb={6}
        >
          HR Services
        </Heading>
        <Stack
          gap={{ base: 12, md: 10, lg: 12, xl: '7vw' }}
          direction={{ base: 'column', md: 'row' }}
        >
          <Img
            h={{ base: '40vh', md: '50vh', lg: '60vh' }}
            w={{ base: '100%', md: '50%' }}
            borderRadius={'5px'}
            src={hradvisory}
          />
          <Stack>
            {/* <Text fontWeight={'600'} fontSize={'15px'} color="#FF6217">
              HR Technology Advisory
            </Text> */}
            <Heading
              color={'#17496D'}
              fontSize={{ base: '1rem', md: '1.8rem', lg: '1.8rem' }}
              fontWeight={'700'}
              className="heading"
              mb={4}
              mt={2}
            >
              HR Technology Advisory
            </Heading>
            <Text
              whiteSpace="pre-line"
              color={'#343434'}
              fontSize={{ base: '0.75rem', md: '0.85rem', lg: '0.95rem' }}
              fontWeight={'500'}
              marginBottom={'0'}
              lineHeight={{ base: '23px', lg: '27px' }}
            >
              {text}
            </Text>
            <Button
              mt={3}
              onClick={() => nav('/contact-us')}
              size={{ base: 'sm', md: 'lg' }}
              leftIcon={<Image src={contactus} />}
              color="white"
              _hover={{}}
              bg="linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)"
              boxShadow={'0px 2.25px 9px 0px rgba(74, 58, 255, 0.18)'}
              borderRadius={'5px'}
              fontWeight={'400'}
              w="fit-content"
            >
              Work with us
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default HrTechnologyAdvisory;
