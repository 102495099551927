import React from 'react';
import './header.css'; // Import the CSS file
import { Text } from '@chakra-ui/react';

const TextTrimmer = ({ text, maxWords }) => {
  const truncatedText = text.split(' ').slice(0, maxWords).join(' ');

  return (
    <Text fontSize="sm" color="#343434" fontWeight={'500'} className="ellipsis-text">
      {truncatedText}
      {text.split(' ').length > maxWords && '...'}
    </Text>
  );
};

export default TextTrimmer;
