import React, { useState } from 'react';
import {
  Flex,
  Text,
  Input,
  Button,
  Link,
  Stack,
  Img,
  useToast,
  Image,
} from '@chakra-ui/react';
import logo from '../../assets/images/logowhite.png';
import verifyEmail from '../../assets/images/resendEmail.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { forgotPasswordService, resendEmailService } from '../../services/Service';
const ResendEmail = () => {
  const location = useLocation();
  const nav = useNavigate();
  const toast = useToast();
  const [loading, setloading] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    setloading(true);
    // Handle successful form submission
    const res = await resendEmailService(location?.state);
    console.log('🚀 ~ file: Login.js:78 ~ handleSubmit ~ res:', res);
    if (res.success) {
      setloading(false);
      toast({
        title: res.message,
        status: 'success',
        duration: 3000,
        position: 'top-right',
        isClosable: true,
      });
    } else {
      setloading(false);
      toast({
        title: res.message,
        status: 'error',
        duration: 3000,
        position: 'top-right',
        isClosable: true,
      });
    }
  };
  return (
    <Stack gap={0} overflow={'hidden'} maxWidth={'100%'} alignItems={'center'}>
      <Stack
        w="100%"
        justifyContent={'center'}
        direction="row"
        bg={
          'var(--gradient, linear-gradient(15deg, #17496D 0%, #418DB8 92.71%))'
        }
        height={'7vh'}
        alignItems={'center'}
        boxShadow={'0px 8px 30px 0px rgba(13, 24, 33, 0.08)'}
      >
        <Img w={6} src={logo} />
        <Text fontSize={'lg'} color="white">
          SnSHRhub
        </Text>
      </Stack>
      <Stack
        bg="#F8FBFF"
        h="93vh"
        direction="row"
        justifyContent={'center'}
        alignItems={'center'}
        width="100vw"
      >
        <Flex
          h="full"
          px={{ base: 6, sm: 8, md: 12, lg: 24 }}
          height={'100%'}
          width={{ base: '100%', md: '60%', lg: '50%' }}
          textAlign={'left'}
          align="center"
        >
          <Stack alignItems={'center'} width="100%" spacing="6">
            <Image w={150} h={150} src={verifyEmail} />
            <Stack textAlign={'center'}>
              <Text fontWeight={'600'} fontSize={'xl'} color={'#0D1821'}>
                Verify your Email Address
              </Text>
              <Text>
                Before you can use our app, we ask you to verify your email
                address once. We just sent a verification email. You click the button below to request a new one.
              </Text>
            </Stack>
            <Stack alignItems={'center'} spacing={6}>
              <Button
                fontWeight={'500'}
                w="100%"
                color={'white'}
                borderRadius={'full'}
                bg="var(--gradient, linear-gradient(15deg, #17496D 0%, #418DB8 92.71%))"
                _hover={{}}
                type="submit"
                onClick={handleSubmit}
                isLoading={loading}
              >
                Resend Verification link
              </Button>
              <Link onClick={() => nav('/')} fontSize={'md'} color="#3C98FD">
                Back To Login
              </Link>
            </Stack>
          </Stack>
        </Flex>
      </Stack>
    </Stack>
  );
};

export default ResendEmail;
