import React from 'react';
import Layout from '../../layout/Layout';
import { Button, Heading, Image, Img, Stack, Text } from '@chakra-ui/react';
import hrspecificchange from '../../../assets/images/hrspecificchange.jpg';
import contactus from '../../../assets/images/contactus.svg';
import { useNavigate } from 'react-router-dom';

const SpecificChangeManagment = () => {
  const nav = useNavigate();
  const text = `Welcome to a transformative era in human resources, where change is not just inevitable but embraced as a catalyst for growth and innovation. At snsHRhub, we introduce the SnS Change Model, a comprehensive framework designed to navigate the intricacies of HR transformation with precision and effectiveness. In this dynamic landscape, change is not merely a process; it's an opportunity to elevate your human resources practices to new heights.
  HR Specific Change Management Services:
  Change Readiness Assessment:
  Evaluating the organization's preparedness for change, identifying potential challenges, and establishing a baseline for the change management process.
  Communication Strategy Development:
  Crafting effective communication plans to ensure transparent and consistent messaging throughout the change process, keeping all stakeholders informed and engaged.
  Stakeholder Engagement and Alignment:
  Identifying key stakeholders, understanding their concerns, and developing strategies to align their interests with the organizational change goals.
  Leadership Development for Change:
  Providing training and support for leaders to effectively lead and navigate the organization through the change, fostering a culture of adaptability and resilience.
  Employee Training and Development:
  Designing and implementing training programs to equip employees with the skills and knowledge necessary to thrive in the changing environment.
  `;
  // `Welcome to a transformative era in human resources, where change is not just inevitable but embraced as a catalyst for growth and innovation. At snsHRhub, we introduce the SnS Change Model, a comprehensive framework designed to navigate the intricacies of HR transformation with precision and effectiveness. In this dynamic landscape, change is not merely a process; it's an opportunity to elevate your human resources practices to new heights.

  // HR Specific Change Management Services:

  // Change Readiness Assessment:
  // Evaluating the organization's preparedness for change, identifying potential challenges, and establishing a baseline for the change management process.

  // Communication Strategy Development:
  // Crafting effective communication plans to ensure transparent and consistent messaging throughout the change process, keeping all stakeholders informed and engaged.

  // Stakeholder Engagement and Alignment:
  // Identifying key stakeholders, understanding their concerns, and developing strategies to align their interests with the organizational change goals.

  // Leadership Development for Change:
  // Providing training and support for leaders to effectively lead and navigate the organization through the change, fostering a culture of adaptability and resilience.

  // Employee Training and Development:
  // Designing and implementing training programs to equip employees with the skills and knowledge necessary to thrive in the changing environment.

  // Resistance Management:
  // Proactively addressing and managing resistance to change by identifying root causes and implementing strategies to overcome resistance at various organizational levels.

  // Cultural Transformation:
  // Facilitating cultural shifts within the organization to align with the desired changes, fostering a positive and adaptive work environment.

  // Organizational Design and Restructuring:
  // Assessing and redesigning organizational structures to align with the new strategic direction, optimizing efficiency and effectiveness.

  // Performance Management Alignment:
  // Aligning performance management processes with the new organizational goals and ensuring that performance metrics support the desired outcomes of the change initiative.

  // Monitoring and Evaluation:
  // Implementing mechanisms for ongoing monitoring and evaluation of the change process, allowing for adjustments and refinements as needed.

  // Continuous Improvement Strategies:
  // Establishing frameworks for continuous improvement to ensure that the organization remains agile and responsive to future changes.

  // Technology Integration Support:
  // Providing support and guidance for the integration of new technologies that may be part of the change initiative, ensuring a smooth transition and optimal utilization.

  // Join snsHRhub on this transformative journey, where the SnS Change Model becomes your guide to not just navigate change but to thrive in it. Welcome to a future where change is not a challenge but an opportunity for lasting success in the realm of human resources.`
  return (
    <Layout>
      <Stack width={'100%'} px={{ base: 3, md: 6, lg: 12, xl: 20 }} py={6}>
        <Heading
          color={'#17496D'}
          fontSize={{ base: '1rem', md: '1.8rem', lg: '2rem' }}
          fontWeight={'700'}
          className="heading"
          mb={6}
        >
          HR Services
        </Heading>
        <Stack
          gap={{base:12,md:10,lg:12,xl:'7vw'}}
          direction={{ base: 'column', md: 'row' }}
        >
          <Img
            w={{ base: '100%', md: '50%' }}
            borderRadius={'5px'}
            h={{ base: '40vh', md: '50vh', lg: '60vh' }}
            src={hrspecificchange}
          />
          <Stack>
            {/* <Text fontWeight={'600'} fontSize={'15px'} color="#FF6217">
              HR Specific Change Management
            </Text> */}
            <Heading
              color={'#17496D'}
              fontSize={{ base: '1rem', md: '1.8rem', lg: '1.8rem' }}
              fontWeight={'700'}
              className="heading"
              mb={4}
              mt={2}
            >
              HR Change Management
            </Heading>
            <Text
              color={'#343434'}
              fontSize={{ base: '0.75rem', md: '0.85rem', lg: '0.95rem' }}
              fontWeight={'500'}
              marginBottom={'0'}
              whiteSpace={'pre-line'}
              lineHeight={{ base: '23px', lg: '27px' }}
            >
              {text}
            </Text>
            <Button
              mt={3}
              onClick={() => nav('/contact-us')}
              size={{ base: 'sm', md: 'lg' }}
              leftIcon={<Image src={contactus} />}
              color="white"
              _hover={{}}
              bg="linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)"
              boxShadow={'0px 2.25px 9px 0px rgba(74, 58, 255, 0.18)'}
              borderRadius={'5px'}
              fontWeight={'400'}
              w="fit-content"
            >
              Work with us
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default SpecificChangeManagment;
