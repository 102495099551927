import React from 'react';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Checkout from './Checkout';

const Stripe = ({ amount }) => {

  const STRIPE_PUBLISHABLE_KEY =
    'pk_live_51OO1KTGFSjEkmDCD56EcW5fzcF5IIgvp5ChclSFSdD33IRaXcku4sqYdL946066deRvEyL0L3WAu6KTKRlUhQFIe00ZFP6LebV';

  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({elements }) => (
          <Checkout
            amount={amount}
            stripe={stripePromise}
            elements={elements}
          />
        )}
      </ElementsConsumer>
    </Elements>
  );
};

export default Stripe;

// import { CardElement, PaymentElement } from '@stripe/react-stripe-js';
// import { useState } from 'react';
// import { useStripe, useElements } from '@stripe/react-stripe-js';
// import { Box, Button } from '@chakra-ui/react';
// const CARD_ELEMENT_OPTIONS = {
//   iconStyle: 'solid',
//   hidePostalCode: true,
//   style: {
//     base: {
//       iconColor: 'rgb(240, 57, 122)',
//       color: 'rgb(240, 57, 122)',
//       fontSize: '16px',
//       fontFamily: '"Open Sans", sans-serif',
//       fontSmoothing: 'antialiased',
//       '::placeholder': {
//         color: '#CFD7DF',
//       },
//     },
//     invalid: {
//       color: '#e5424d',
//       ':focus': {
//         color: '#303238',
//       },
//     },
//   },
// };
// export default function Stripe({ amount }) {
//   const stripe = useStripe();
//   // const dispatch = useDispatch();

//   const elements = useElements();

//   const [isLoading, setIsLoading] = useState(false);

//   const handleSubmit = async e => {
//     e.preventDefault();
//     if (!stripe || !elements) {
//       setIsLoading(false);

//       return;
//     }

//     setIsLoading(true);

//     try {
//       const { error, paymentIntent } = await stripe.confirmPayment({
//         elements,
//       });

//       if (error) {
//         setIsLoading(false);
//       } else if (paymentIntent && paymentIntent.status === 'succeeded') {
//       } else {
//         setIsLoading(false);
//       }
//     } catch (error) {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <>
//       <form onSubmit={handleSubmit} style={{ justifyContent: 'center' }}>
//         <Box
//           alignItems={'center'}
//           justifyContent={'center'}
//           borderRadius={'lg'}
//           boxShadow={'lg'}
//           w="full"
//           minH={'5vh'}
//           p={'2'}
//         >
//           <CardElement options={CARD_ELEMENT_OPTIONS} />
//         </Box>
//         <Button
//           mt={'4'}
//           width={'full'}
//           backgroundColor={'#368CCD'}
//           height={'40px'}
//           textAlign={'center'}
//           color={'white'}
//           size={'md'}
//           isLoading={isLoading}
//           type="submit"
//           fontSize={'sm'}
//         >
//           PAY NOW
//         </Button>
//       </form>
//     </>
//   );
// }
// export { Stripe };
