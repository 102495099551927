import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SignupPage from '../../components/Signup/SignupPage';
import Login from '../../components/login/Login';
import ForgotPasswordPage from '../../components/forgotpassword/ForgotPasswordPage';
import ResetPassword from '../../components/resetpassword/ResetPassword';
import ChangePassword from '../../components/changepassword/ChangePassword';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import ResendEmail from '../../components/verifyemail/ResendEmail';
import VerifyEmail from '../../components/verifyemail/VerifyEmail';
import Home from '../../components/home/Home';
import DigitalSubscription from '../../components/subscription/DigitalSubscription';
import HrNews from '../../components/hrnews/HrNews';
import NewsDetail from '../../components/newdetail/NewsDetail';
import HrTechTrends from '../../components/hrtechtrends/HrTechTrends';
import HrEmploymentLaw from '../../components/hremployment/HrEmploymentLaw';
import TechTrendsDetail from '../../components/hrtechtrends/TechTrendsDetail';
import EmploymentLawDetailPage from '../../components/hremployment/EmploymentLawDetailPage';
import SpecificChangeManagment from '../../components/hrservices/hrspecificchange/SpecificChangeManagment';
import HrTechnologyAdvisory from '../../components/hrservices/hrtechnologyadvisory/HrtechnologyAdvisory';
import HrDigitalTranformation from '../../components/hrservices/hrdigitaltransformation/HrDigitalTranformation';
import HrPolicyTemplate from '../../components/hrresources/HrPolicyTemplate';
import HrProcessTemplate from '../../components/hrresources/HrProcessTemplate';
import Toolkits from '../../components/hrresources/Toolkits';
import AboutUs from '../../components/aboutus/AboutUs';
import ContactUs from '../../components/contactus/ContactUs';
import HrStaggAugmentaion from '../../components/hrservices/staffaugmentation/HrStaggAugmentaion';
import DocumentDetailPage from '../../components/hrresources/pdffileviewer/DocumentDetailPage';

const MainRoutes = () => {
  return (
    <Routes>
      <Route
        path="/signup"
        element={
          <PublicRoute>
            <SignupPage />
          </PublicRoute>
        }
      />
      <Route
        path="/forgotpassword"
        element={
          <PublicRoute>
            <ForgotPasswordPage />
          </PublicRoute>
        }
      />
      <Route
        path="/reset-password"
        element={
          <PublicRoute>
            <ResetPassword />
          </PublicRoute>
        }
      />
      <Route
        path="/resend-email"
        element={
          <PublicRoute>
            <ResendEmail />
          </PublicRoute>
        }
      />
      <Route
        path="/change-password/:token"
        element={
          <PublicRoute>
            <ChangePassword />
          </PublicRoute>
        }
      />
      <Route
        path="/verify-email/:token"
        element={
          <PublicRoute>
            <VerifyEmail />
          </PublicRoute>
        }
      />
      {/* <Route
        path="/"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      /> */}
      <Route
        path="/"
        element={
          // <PublicRoute>
            <Home />
          // </PublicRoute>
        }
      />
      <Route
        path="/subscribe"
        element={
          // <PublicRoute>
            <DigitalSubscription />
          // </PublicRoute>
        }
      />
        <Route
        path="/hr-policy-template"
        element={
          // <PublicRoute>
            <HrPolicyTemplate />
          // </PublicRoute>
        }
      />
       <Route
        path="/hr-process-template"
        element={
          // <PublicRoute>
            <HrProcessTemplate />
          // </PublicRoute>
        }
      />
       <Route
        path="/toolkits"
        element={
          // <PublicRoute>
            <Toolkits />
          // </PublicRoute>
        }
      />
      <Route
        path="/hr-news"
        element={
          // <PublicRoute>
            <HrNews />
          // </PublicRoute>
        }
      />
       <Route
        path="/hr-techtrends"
        element={
          // <PublicRoute>
            <HrTechTrends />
          // </PublicRoute>
        }
      />
        <Route
        path="/employment-laws"
        element={
          // <PublicRoute>
            <HrEmploymentLaw />
          // </PublicRoute>
        }
      />
      <Route
        path="/news-detail"
        element={
          // <PublicRoute>
            <NewsDetail />
          // </PublicRoute>
        }
      />
      <Route
        path="/specific-change-management"
        element={
            <SpecificChangeManagment />
        }
      />
       <Route
        path="/technology-advisory"
        element={
            <HrTechnologyAdvisory />
        }
      />
       <Route
        path="/digital-transformation"
        element={
            <HrDigitalTranformation />
        }
      />
      <Route
        path="/staff-augmentation"
        element={
            <HrStaggAugmentaion />
        }
      />
      <Route
        path="/techtrends-detail"
        element={
          // <PublicRoute>
            <TechTrendsDetail />
          // </PublicRoute>
        }
      />
      <Route
        path="/about-us"
        element={
            <AboutUs />
        }
      />
       <Route
        path="/contact-us"
        element={
            <ContactUs />
        }
      />
      <Route
        path="/read-resources"
        element={
            <DocumentDetailPage />
        }
      />
      <Route
        path="/employmentlaws-detail"
        element={
          // <PublicRoute>
            <EmploymentLawDetailPage />
          // </PublicRoute>
        }
      />
      <Route
        path="/:token"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
    </Routes>
  );
};

export default MainRoutes;
