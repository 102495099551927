import React, { useEffect, useState } from 'react';
import { Heading, Stack, Text, Button, Divider, Img } from '@chakra-ui/react';
import Layout from '../layout/Layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { getBlogsService } from '../../services/Service';

const HrEmploymentLaw = () => {
  const [blogs, setblogs] = useState();

  const nav = useNavigate();

  useEffect(() => {
    const getBlogs = async () => {
      const res = await getBlogsService();
      console.log('🚀 ~ file: HRNews.js:15 ~ getBlogs ~ res:', res);
      if (res.success) {
        setblogs(res.data);
      }
    };
    getBlogs();
  }, []);
  function truncateDescription(description, wordCount) {
    if (!description) return '';

    const words = description.split(' ');

    if (words.length <= wordCount) {
      return description;
    } else {
      return words.slice(0, wordCount).join(' ') + '...';
    }
  }
  return (
    <Layout>
      <Stack width={'100%'} px={{ base: 3, md: 6, lg: 12, xl: 20 }} py={5}>
        <Heading
          className="heading"
          color={'#17496D'}
          fontSize={{ base: '1.3rem', md: '1.4rem', lg: '1.4rem' }}
          fontWeight={'700'}
          mb={1}
        >
          Employment Law & News
        </Heading>
        {blogs && blogs[0] && blogs[0].mainContent && (
          <Img
            cursor={'pointer'}
            onClick={() =>
              nav('/employmentlaws-detail', { state: blogs && blogs[0]?._id })
            }
            height={'80vh'}
            borderRadius={'2xl'}
            src={blogs && blogs[0]?.mainContent}
          />
        )}
        <Stack>
          <Heading
            color={'#000000'}
            fontSize={{ base: '1.5rem', md: '1.4rem', lg: '2rem' }}
            fontWeight={'700'}
            pt={1}
          >
            {blogs && blogs[0]?.title}
          </Heading>
          <Text
            color={'#343434'}
            fontSize={{ base: '1rem', md: '1.1rem', lg: '1rem' }}
            fontWeight={'500'}
            lineHeight={{ base: '25px', md: '25px', lg: '25px' }}
          >
            {blogs && truncateDescription(blogs[0]?.description, 50)}
          </Text>
          <Divider borderColor={'rgba(151, 151, 151, 1)'} my={3} />
          {blogs?.map((blog, index) =>
            // Check if it's not the first element (index > 0)
            index > 0 ? (
              <Stack
                w="100%"
                key={index}
                // alignItems={'center'}
                px={{ base: '5%', xl: '10%' }}
              >
                <Stack
                  w="100%"
                  direction={{ base: 'column', lg: 'row' }}
                  gap={5}
                >
                  <Img
                    onClick={() =>
                      nav('/employmentlaws-detail', { state: blog?._id })
                    }
                    w={'24%'}
                    cursor="pointer"
                    h={'165px'}
                    src={blog?.mainContent}
                    borderRadius={'xl'}
                  />
                  <Stack w="75%" justifyContent={'center'}>
                    <Heading
                      color={'rgba(0, 0, 0, 0.95)'}
                      fontSize={{ base: '1.1rem', md: '1.2rem', lg: '1.3rem' }}
                      fontWeight={'700'}
                      // cursor={'pointer'}
                      // onClick={() => nav('/employmentlaws-detail')}
                    >
                      {blog?.title}
                    </Heading>
                    <Text
                      color={'#343434'}
                      fontSize={{ base: '0.95rem', md: '1rem', lg: '1rem' }}
                      fontWeight={'500'}
                    >
                      {truncateDescription(blog?.description, 50)}
                    </Text>
                  </Stack>
                </Stack>
                <Divider
                  borderColor={'rgba(151, 151, 151, 1)'}
                  w={'100%'}
                  my={2}
                />
              </Stack>
            ) : null
          )}

          {/* <Stack alignItems={'center'} mx={{ base: '10%', xl: '15%' }}>
            <Stack direction={{ base: 'column', lg: 'row' }} gap={5}>
              <Img src={pic2} />
              <Stack justifyContent={'center'}>
                <Heading
                  color={'rgba(0, 0, 0, 0.95)'}
                  fontSize={{ base: '1.1rem', md: '1.2rem', lg: '1.3rem' }}
                  fontWeight={'700'}
                  cursor={'pointer'}
                >
                  France Will Soon Ban Disposable Vapes Under an Anti-Smoking
                </Heading>
                <Text
                  color={'#343434'}
                  fontSize={{ base: '0.95rem', md: '1rem', lg: '1rem' }}
                  fontWeight={'500'}
                >
                  The prime minister said the government would soon unveil its
                  new plan to combat smoking.
                </Text>
                <Text
                  color={'#000000'}
                  fontSize={{ base: '0.65rem', md: '0.75rem', lg: '0.75rem' }}
                  fontWeight={'700'}
                >
                  By Associated Press
                  <chakra.span color={'#DA291C'}>
                    {' '}
                    September 3, 2023
                  </chakra.span>
                </Text>
              </Stack>
            </Stack>
            <Divider borderColor={'rgba(151, 151, 151, 1)'} w={'100%'} my={2} />
          </Stack>
          <Stack alignItems={'center'} mx={{ base: '10%', xl: '15%' }}>
            <Stack direction={{ base: 'column', lg: 'row' }} gap={5}>
              <Img src={pic3} />
              <Stack justifyContent={'center'}>
                <Heading
                  color={'rgba(0, 0, 0, 0.95)'}
                  fontSize={{ base: '1.1rem', md: '1.2rem', lg: '1.3rem' }}
                  fontWeight={'700'}
                  cursor={'pointer'}
                >
                  Taiwan Suspends Work and Transport as Typhoon Haikui Hits
                </Heading>
                <Text
                  color={'#343434'}
                  fontSize={{ base: '0.95rem', md: '1rem', lg: '1rem' }}
                  fontWeight={'500'}
                >
                  The storm made landfall in Taitung county on the
                  Pacific-facing east coast around 3 p.m.
                </Text>
                <Text
                  color={'#000000'}
                  fontSize={{ base: '0.65rem', md: '0.75rem', lg: '0.75rem' }}
                  fontWeight={'700'}
                >
                  By Associated Press
                  <chakra.span color={'#DA291C'}>
                    {' '}
                    September 3, 2023
                  </chakra.span>
                </Text>
              </Stack>
            </Stack>
            <Divider borderColor={'rgba(151, 151, 151, 1)'} w={'100%'} my={2} />
          </Stack>
          <Stack alignItems={'center'} mx={{ base: '10%', xl: '15%' }}>
            <Stack direction={{ base: 'column', lg: 'row' }} gap={5}>
              <Img src={pic4} />
              <Stack justifyContent={'center'}>
                <Heading
                  color={'rgba(0, 0, 0, 0.95)'}
                  fontSize={{ base: '1.1rem', md: '1.2rem', lg: '1.3rem' }}
                  fontWeight={'700'}
                  cursor={'pointer'}
                >
                  India Launches a Spacecraft to Study the Sun
                </Heading>
                <Text
                  color={'#343434'}
                  fontSize={{ base: '0.95rem', md: '1rem', lg: '1rem' }}
                  fontWeight={'500'}
                >
                  The Aditya-L1 spacecraft took off on board a satellite launch
                  vehicle from the Sriharikota space center.
                </Text>
                <Text
                  color={'#000000'}
                  fontSize={{ base: '0.65rem', md: '0.75rem', lg: '0.75rem' }}
                  fontWeight={'700'}
                >
                  By ASHOK SHARMA and AIJAZ HUSSAIN / AP
                  <chakra.span color={'#DA291C'}>
                    {' '}
                    September 2, 2023
                  </chakra.span>
                </Text>
              </Stack>
            </Stack>
            <Divider borderColor={'rgba(151, 151, 151, 1)'} w={'100%'} my={2} />
          </Stack>
          <Stack alignItems={'center'} mx={{ base: '10%', xl: '15%' }}>
            <Stack direction={{ base: 'column', lg: 'row' }} gap={5}>
              <Img src={pic5} />
              <Stack justifyContent={'center'}>
                <Heading
                  color={'rgba(0, 0, 0, 0.95)'}
                  fontSize={{ base: '1.1rem', md: '1.2rem', lg: '1.3rem' }}
                  fontWeight={'700'}
                  cursor={'pointer'}
                >
                  Nobel Foundation Withdraws Invite to Russia, Iran, Belarus
                </Heading>
                <Text
                  color={'#343434'}
                  fontSize={{ base: '0.95rem', md: '1rem', lg: '1rem' }}
                  fontWeight={'500'}
                >
                  Saturday's announcement was widely praised in Sweden by
                  politicians.
                </Text>
                <Text
                  color={'#000000'}
                  fontSize={{ base: '0.65rem', md: '0.75rem', lg: '0.75rem' }}
                  fontWeight={'700'}
                >
                  By Associated Press
                  <chakra.span color={'#DA291C'}>
                    {' '}
                    September 2, 2023
                  </chakra.span>
                </Text>
              </Stack>
            </Stack>
            <Divider borderColor={'rgba(151, 151, 151, 1)'} w={'100%'} my={2} />
          </Stack>
          <Stack alignItems={'center'} mx={{ base: '10%', xl: '15%' }}>
            <Stack direction={{ base: 'column', lg: 'row' }} gap={5}>
              <Img src={pic6} />
              <Stack justifyContent={'center'}>
                <Heading
                  color={'rgba(0, 0, 0, 0.95)'}
                  fontSize={{ base: '1.1rem', md: '1.2rem', lg: '1.3rem' }}
                  fontWeight={'700'}
                  cursor={'pointer'}
                >
                  Singapore’s Former Deputy Premier Tharman Wins Presidency by a
                  Landslide
                </Heading>
                <Text
                  color={'#343434'}
                  fontSize={{ base: '0.95rem', md: '1rem', lg: '1rem' }}
                  fontWeight={'500'}
                >
                  Candidates with long-standing ties to the ruling party have
                  dominated the largely-ceremonial post since it became an
                  elected position three decades ago.
                </Text>
                <Text
                  color={'#000000'}
                  fontSize={{ base: '0.65rem', md: '0.75rem', lg: '0.75rem' }}
                  fontWeight={'700'}
                >
                  By Philip J. Heijmans / Bloomberg
                  <chakra.span color={'#DA291C'}>
                    {' '}
                    September 1, 2023
                  </chakra.span>
                </Text>
              </Stack>
            </Stack>
          </Stack> */}
          <Stack>
            <Button
              variant="outline"
              borderRadius={'5px'}
              borderColor={'#418DB8'}
              bgColor={'white'}
              color={'#17496D'}
              fontWeight={'500'}
              fontSize={{ base: '1rem', xl: '1.05rem' }}
              _hover={{
                color: 'white',
                bgColor: '#418DB8',
              }}
              mx={'32%'}
              mt={8}
              mb={5}
            >
              See More
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default HrEmploymentLaw;
