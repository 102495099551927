import React, { useEffect, useState } from 'react';
import Layout from '../../layout/Layout';
import {
  Button,
  Heading,
  Img,
  Input,
  InputGroup,
  InputRightElement,
  ListItem,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  UnorderedList,
  chakra,
} from '@chakra-ui/react';
import { Bars } from 'react-loader-spinner';
import { BsSearch } from 'react-icons/bs';
import companyLogo from '../../../assets/images/companyLogo.svg';
import favIcon from '../../../assets/images/favIcon.svg';
import { getAllJobsService } from '../../../services/Service';
const HrStaggAugmentaion = () => {
  const [jobs, setJobs] = useState();
  const [loading, setloading] = useState();
  useEffect(() => {
    setloading(true)
    getAllJobsService().then(res => {
      setloading(false)
      if (res.success) {
        setJobs(res.jobs);
      }
    });
  }, []);

  return (
    <Layout>
        {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <Bars
              height="60"
              width="60"
              color="#17496D"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      )}
      {' '}
      <Stack width={'100%'} px={{ base: 3, md: 6, lg: 12, xl: 20 }} py={5}>
        <Heading
          color={'#17496D'}
          fontSize={{ base: '1rem', md: '1.8rem', lg: '2rem' }}
          fontWeight={'700'}
          className="heading"
        >
          HR Technology Staff Augmentation
        </Heading>
        <Stack
          mt={6}
          direction="row"
          justifyContent={'space-between'}
          alignItems={'baseline'}
          w="100%"
        >
          <Stack w="40%">
            <Tabs variant="unstyled">
              <TabList w="fit-content" borderRadius="full" p={1.5} bg="#E2E8F0">
                <Tab
                  color="#939393"
                  _selected={{
                    color: '#E2E8F0',
                    bg: 'linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)',
                    borderRadius: 'full',
                  }}
                >
                  Best Matches
                </Tab>
                <Tab
                  color="#939393"
                  _selected={{
                    color: '#E2E8F0',
                    bg: 'linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)',
                    borderRadius: 'full',
                  }}
                >
                  Featured
                </Tab>
                <Tab
                  color="#939393"
                  _selected={{
                    color: '#E2E8F0',
                    bg: 'linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)',
                    borderRadius: 'full',
                  }}
                >
                  Most Recent
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel pl={0}>
                  <Stack spacing={6}>
                    {jobs?.map(job => (
                      <Stack bg="#E2E8F0" borderRadius={'xl'} p={6}>
                        <Stack direction="row" alignItems="start" justifyContent={'space-between'}>
                        <Stack direction="row" alignItems="start">
                          <Img
                            borderRadius={'lg'}
                            w={20}
                            src={job.company_logo}
                          />
                          <Stack spacing={1}>
                            <Text fontWeight={'600'} fontSize="lg">
                              {job.job_title}
                            </Text>
                            <Text color="#6C6C6C" fontSize="xs">
                              {job.job_description}
                            </Text>
                          </Stack>
                          </Stack>
                          <Img src={favIcon} />
                        </Stack>
                        <Text color="#6C6C6C" fontSize="sm">
                          {job.job_location}
                        </Text>
                        <Stack
                          mt={2}
                          direction="row"
                          alignItems="center"
                          justifyContent={'space-between'}
                        >
                          <Text
                            bg="#EFEFEF"
                            p={1}
                            borderRadius={'5px'}
                            color="#6C6C6C"
                            fontSize="xs"
                          >
                            {job.job_type}
                          </Text>
                          <Text color="#6C6C6C" fontSize="xs">
                            <chakra.span color="black" fontWeight={'600'}>
                              Applied
                            </chakra.span>
                            : 10 to 15
                          </Text>
                        </Stack>
                      </Stack>
                    ))}
                  </Stack>
                </TabPanel>
                <TabPanel pl={0}></TabPanel>
                <TabPanel pl={0}></TabPanel>
              </TabPanels>
            </Tabs>
          </Stack>
          <Stack alignItems={'end'} w="60%">
            <InputGroup w="40%" size="md">
              <Input
                placeholder="Search Job"
                border="1.5px solid #D1D1D1"
                borderRadius={'5px'}
                pr="4.5rem"
                _hover={{}}
                _active={{}}
                _focusVisible={{}}
              />
              <InputRightElement width="4.5rem">
                <BsSearch color="#C5C7CD" />
              </InputRightElement>
            </InputGroup>
            <Stack mt={4} w="100%" bg="#E2E8F0" borderRadius={'xl'} p={6}>
              <Stack>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Text fontWeight={'600'} fontSize={'md'}>
                    Job Title
                  </Text>
                  <Img src={favIcon} />
                </Stack>
                <Stack direction="row" alignItems="start">
                  <Img
                    borderRadius={'lg'}
                    w={20}
                    src={jobs && jobs[0]?.company_logo}
                  />
                  <Stack spacing={0}>
                    <Text color="#6D6D6D" fontSize="11px">
                      {jobs && jobs[0]?.company_name}
                    </Text>
                    <Text fontWeight={'600'} fontSize="lg">
                      {jobs && jobs[0]?.job_title}
                    </Text>
                  </Stack>
                </Stack>
                <Button
                  my={5}
                  w="fit-content"
                  size="md"
                  color="white"
                  fontWeight={'400'}
                  _hover={{}}
                  bg="linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)"
                  boxShadow={'0px 2.25px 9px 0px rgba(74, 58, 255, 0.18)'}
                  borderRadius={'5px'}
                >
                  Apply Here
                </Button>
                <Stack spacing={2}>
                  <Text fontWeight={'600'} fontSize="lg">
                    Job description
                  </Text>
                  <Text color="#6D6D6D" fontSize="sm">
                    {jobs && jobs[0]?.job_description}
                  </Text>
                </Stack>
                <Stack mt={4} spacing={2}>
                  <Text fontWeight={'600'} fontSize="lg">
                    Job Requirements
                  </Text>
                  <Text color="#6D6D6D" fontSize="sm">
                    {jobs && jobs[0]?.job_requirements}
                  </Text>
                  {/* <UnorderedList fontSize={'sm'} color="#6C6C6C">
                    <ListItem>2 - 3 years of UI/UX design experience</ListItem>
                    <ListItem>
                      A strong portfolio showcasing previous work on web and
                      mobile interfaces, demonstrating a deep understanding of
                      user-centered design principles
                    </ListItem>
                    <ListItem>
                      Ability to design and implement effective UX processes
                      such as User Interviews, Personas, User Journey Mappings,
                      Prototyping, Feedback Loops, Data-Driven Design, A/B
                      Testing, etc
                    </ListItem>
                    <ListItem>
                      Skilled with UI/UX design and prototyping tools such as
                      Figma, InVision, Adobe XD, Sketch, etc
                    </ListItem>
                    <ListItem>
                      Developing and conceptualising a comprehensive UI/UX
                      design that is consistent with product specification
                    </ListItem>
                  </UnorderedList> */}
                </Stack>
                <Stack mt={4} spacing={2}>
                  <Text fontWeight={'600'} fontSize="lg">
                    Job Qualifications
                  </Text>
                  <Text color="#6D6D6D" fontSize="sm">
                    {jobs && jobs[0]?.job_qualification}
                  </Text>
                  {/* <UnorderedList fontSize={'sm'} color="#6C6C6C">
                    <ListItem>
                      Lorem ipsum dolor sit amet consectetur. Tellus egestas et
                      cras enim tincidunt. Et ut vitae auctor dui in nullam
                      ipsum eget. Risus morbi dignissim mauris amet blandit
                      tempus justo sed a. Feugiat tincidunt suspendisse fames a
                      nullam.
                    </ListItem>
                    <ListItem>
                      Convallis sed imperdiet augue leo ut ut. Augue erat dolor
                      sit dolor elit orci felis. Neque quis gravida eu viverra
                      sem quis nisl augue. Elit tristique in lectus diam
                      pellentesque metus quam eu est. Non volutpat a sit
                      elementum parturient velit diam.
                    </ListItem>
                  </UnorderedList> */}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default HrStaggAugmentaion;
