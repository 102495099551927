import React, { useState } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Button,
  Center,
  Stack,
  Text,
  color,
  Spacer,
  Textarea,
  chakra,
  Heading,
  Checkbox,
} from '@chakra-ui/react';

import './ContactUs.css';
import connectUSImg from '../../assets/images/connect-us.jpg';
import Layout from '../layout/Layout';

const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  return (
    <Layout>
      {/*Connect us Form*/}
      <Stack width={'100%'} px={{ base: 3, md: 6, lg: 12, xl: 20 }} py={5}>
        <Heading
          color={'#17496D'}
          fontSize={{ base: '1rem', md: '1.8rem', lg: '2rem' }}
          fontWeight={'bold'}
          className="heading"
          mb={2}
        >
          Contact us
        </Heading>
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          justify="center"
          align="start"
        >
          {/* Image */}
          <Box w={{ base: '100%', lg: '45%' }} py={2}>
            <img
              className="contactus-image"
              src={connectUSImg}
              alt="Your Image"
              style={{
                width: '100%',
                height: '80vh',
                borderRadius: '5px',
                objectFit: 'cover',
              }}
            />
          </Box>

          {/* Form */}
          <Box
            w={{ base: '100%', lg: '50%' }}
            mt={{ base: '40px', lg: '0' }}
            ml={{ base: '0', lg: '40px' }}
          >
            <form>
              <Stack mb={4} spacing={2}>
                <Heading
                  color={'#17496D'}
                  fontSize={{ base: '1rem', md: '1.5rem', lg: '1.5rem' }}
                  fontWeight={'bold'}
                  className="heading"
                  mb={2}
                >
                  Let’s work together
                </Heading>
                <Text pr={3} fontSize={'md'} color="#343434">
                  We look forward to meeting you. Let us know how we can support
                  your organization by filling out the form below. You can also
                  email us at{' '}
                  <chakra.span fontWeight={'600'} color="#17496D">
                    info@snshrhub.com
                  </chakra.span>
                </Text>
              </Stack>
              <Stack spacing={4}>
                <Stack spacing={6} direction={{ base: 'column', lg: 'row' }}>
                  <FormControl>
                    <FormLabel
                      color={'#17496D'}
                      fontSize={{ base: '1rem', md: '1rem', lg: '1rem' }}
                      fontWeight={'600'}
                    >
                      First Name<chakra.span color={'#DC2D2D'}>*</chakra.span>
                    </FormLabel>
                    <Input
                      _hover={{}}
                      fontSize={'md'}
                      placeholder="John"
                      border={'1px solid #17496D'}
                      placeholderTextColor="rgba(23, 73, 109, 0.30)"
                      type="text"
                      name="fName"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      color={'#17496D'}
                      fontSize={{ base: '1rem', md: '1rem', lg: '1rem' }}
                      fontWeight={'600'}
                    >
                      Last Name<chakra.span color={'#DC2D2D'}>*</chakra.span>
                    </FormLabel>
                    <Input
                      _hover={{}}
                      fontSize={'md'}
                      placeholder="John"
                      border={'1px solid #17496D'}
                      placeholderTextColor="rgba(23, 73, 109, 0.30)"
                      type="text"
                      name="lName"
                    />
                  </FormControl>
                </Stack>
                <Stack spacing={6} direction={{ base: 'column', lg: 'row' }}>
                  <FormControl>
                    <FormLabel
                      color={'#17496D'}
                      fontSize={{ base: '1rem', md: '1rem', lg: '1rem' }}
                      fontWeight={'600'}
                    >
                      Company Name
                    </FormLabel>
                    <Input
                      _hover={{}}
                      fontSize={'md'}
                      placeholder="Microsoft"
                      border={'1px solid #17496D'}
                      placeholderTextColor="rgba(23, 73, 109, 0.30)"
                      type="text"
                      name="companyName"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      color={'#17496D'}
                      fontSize={{ base: '1rem', md: '1rem', lg: '1rem' }}
                      fontWeight={'600'}
                    >
                      Phone No.
                    </FormLabel>
                    <Input
                      _hover={{}}
                      fontSize={'md'}
                      placeholder="829 212 4342"
                      border={'1px solid #17496D'}
                      placeholderTextColor="rgba(23, 73, 109, 0.30)"
                      type="number"
                      name="phone"
                    />
                  </FormControl>
                </Stack>
                <FormControl>
                  <FormLabel
                    color={'#17496D'}
                    fontSize={{ base: '1rem', md: '1rem', lg: '1rem' }}
                    fontWeight={'600'}
                  >
                    Email
                  </FormLabel>
                  <Input
                    _hover={{}}
                    fontSize={'md'}
                    placeholder="Johndoe@sns.comJohn Doe"
                    border={'1px solid #17496D'}
                    placeholderTextColor="rgba(23, 73, 109, 0.30)"
                    type="email"
                    name="email"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel
                    color={'#17496D'}
                    fontSize={{ base: '1rem', md: '1rem', lg: '1rem' }}
                    fontWeight={'600'}
                  >
                    Message
                  </FormLabel>
                  <Textarea
                    rows={8}
                    _hover={{}}
                    fontSize={'md'}
                    placeholder="Lorem ipsum dolor sit amet consectetur. Varius rhoncus egestas vel vitae interdum lacinia consequat. Pellentesque amet ut auctor nec consequat. Vel laoreet volutpat convallis cursus a molestie. Etiam nisi ornare eget ornare."
                    border={'1px solid #17496D'}
                    placeholderTextColor="rgba(23, 73, 109, 0.30)"
                    type="text"
                    name="message"
                  />
                </FormControl>
                <Checkbox color="#343434" size="md">
                  I agree to receive other communications from SnSHRhub in
                  accordance with your privacy notice.
                </Checkbox>
                <Button
                  alignSelf={'end'}
                  px={10}
                  w="fit-content"
                  size="md"
                  color="white"
                  _hover={{}}
                  bg="linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)"
                  boxShadow={'0px 2.25px 9px 0px rgba(74, 58, 255, 0.18)'}
                  borderRadius={'5px'}
                  fontWeight="400"
                >
                  Submit
                </Button>
              </Stack>
            </form>
          </Box>
        </Flex>
      </Stack>
    </Layout>
  );
};

export default ContactUs;
