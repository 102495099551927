import React, { useContext, useEffect, useState } from 'react';
import {
  Heading,
  Stack,
  Text,
  Button,
  Divider,
  Img,
  chakra,
  HStack,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import Layout from '../layout/Layout';
import i from '../../assets/images/i.svg';
import pic1 from '../../assets/images/pdf.png';
import pptx from '../../assets/images/pptx.svg';
import xls from '../../assets/images/xls.svg';
import docx from '../../assets/images/docx.svg';
import pic2 from '../../assets/images/docx.png';
import pic3 from '../../assets/images/pptx.png';
import pic4 from '../../assets/images/xls.png';
import pic5 from '../../assets/images/txt.png';
import pic6 from '../../assets/images/xer.png';
import pic7 from '../../assets/images/jpg.png';
import { useNavigate } from 'react-router-dom';
import BlogsDataContext from '../../context/BlogsContext';
import { Bars } from 'react-loader-spinner';

const HrProcessTemplate = () => {
  const [loading, setloading] = useState();
  const [processTemplates, setprocessTemplates] = useState([]);
  console.log('🚀 ~ HrProcessTemplate ~ processTemplates:', processTemplates);
  const { getBlogsByType } = useContext(BlogsDataContext);
  const nav = useNavigate();

  useEffect(() => {
    setloading(true);
    const getBlogs = async () => {
      const res = await getBlogsByType();
      const toolkitsArray =
        res?.data?.hrResources?.filter(
          blog => blog.category === 'HR Process Templates'
        ) || [];

      setprocessTemplates(toolkitsArray);
      setloading(false);
    };
    getBlogs();
  }, []);
  function truncateUrl(url, maxLength) {
    if (url?.length <= maxLength) {
      return url;
    } else {
      const truncatedUrl = url?.substring(0, maxLength - 3) + '...';
      return truncatedUrl;
    }
  }
  return (
    <Layout>
      {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <Bars
              height="60"
              width="60"
              color="#17496D"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      )}
      <Stack width={'100%'} px={{ base: 3, md: 6, lg: 12, xl: 20 }} py={5}>
        <Heading
          classname="heading"
          color={'#17496D'}
          fontSize={{ base: '1.3rem', md: '1.8rem', lg: '2rem' }}
          fontWeight={'700'}
          mb={6}
        >
          HR Process Template
        </Heading>
        <Text
          whiteSpace="pre-line"
          color={'#343434'}
          fontSize={{ base: '0.75rem', md: '0.85rem', lg: '0.95rem' }}
          fontWeight={'500'}
          marginBottom={'0'}
        >
          Experience the efficiency of streamlined HR processes with snsHRhub's
          HR Process Templates. Designed to optimize workflow and enhance
          organizational efficiency, our ready-to-use templates provide a
          roadmap for seamless HR operations. Whether you're establishing new
          processes or refining existing ones, our templates offer a structured
          approach to HR management. Step into a realm where processes are not
          just defined but elevated, ensuring that your HR workflow aligns with
          best practices and industry standards. Welcome to a world of
          streamlined HR excellence.
        </Text>

        <SimpleGrid columns={[1, 2, 3, 4]} spacing="35px" my={5}>
          {processTemplates?.map(resource => (
            <VStack
              border={'2px'}
              borderColor={'#ebe3e3'}
              borderRadius={'15px'}
              pt={5}
            >
              <Img
                src={
                  resource?.file_name?.split('.').pop() === 'xlsx'
                    ? xls
                    : resource?.file_name?.split('.').pop() === 'pptx'
                    ? pptx:
                    resource?.file_name?.split('.').pop() === 'pdf'?pic1
                    : docx
                }
              />
              <Text
                color={'#17496D'}
                fontSize={{ base: '0.865rem', md: '0.870rem', lg: '0.875rem' }}
                fontWeight={'500'}
                cursor={'pointer'}
                pb={2}
                pt={2}
              >
                {truncateUrl(resource?.file_name, 30)}
                {/* {resource?.file_name} */}
              </Text>
              <Divider borderColor={'#E2E8F0'} />
              <HStack mx={5} alignItems={'center'} mb={2}>
                <Text w={5}>
                  <Img src={i} />
                </Text>
                <Text
                  cursor={'pointer'}
                  color={'rgba(0, 0, 0, 0.7)'}
                  fontSize={{ base: '0.80rem', md: '0.90rem', lg: '0.95rem' }}
                  fontWeight={'700'}
                  onClick={() =>
                    nav('/read-resources', { state: resource?.mainContent })
                  }
                >
                  View Details
                </Text>
              </HStack>
            </VStack>
          ))}
        </SimpleGrid>
      </Stack>
    </Layout>
  );
};

export default HrProcessTemplate;
