import React, { useEffect, useState } from 'react';
import {
  Heading,
  Stack,
  Text,
  Button,
  Divider,
  Img,
  chakra,
} from '@chakra-ui/react';
import Layout from '../layout/Layout';
import LoadingSpinner from '../../assets/images/loadingspinner.gif';

import { useLocation, useNavigate } from 'react-router-dom';
import { getBlogsService } from '../../services/Service';

const HrNews = () => {
  const nav = useNavigate();
  const [blogs, setblogs] = useState();


  useEffect(() => {
    const getBlogs = async () => {
      const res = await getBlogsService();
      console.log('🚀 ~ file: HRNews.js:15 ~ getBlogs ~ res:', res);
      if (res.success) {
        setblogs(res.data);
      }
    };
    getBlogs();
  }, []);
  function truncateDescription(description, wordCount) {
    if (!description) return '';

    const words = description.split(' ');

    if (words.length <= wordCount) {
      return description;
    } else {
      return words.slice(0, wordCount).join(' ') + '...';
    }
  }
  return (
    <Layout>
      {/* <div className="overlay">
          <div className="loaderImage-container">
            <img width={80} height={80} src={LoadingSpinner} alt="" />
          
          </div>
        </div> */}
      <Stack width={'100%'} px={{base:3,md:6,lg:12,xl:20}} py={5}>
        <Heading
          color={'#17496D'}
          fontSize={{ base: '1.3rem', md: '1.8rem', lg: '2rem' }}
          fontWeight={'700'}
          mb={1}
        >
          HR News
        </Heading>

        <Img
          cursor={'pointer'}
          onClick={() => nav('/news-detail', { state: blogs && blogs[0]?._id })}
          height={'80vh'}
          borderRadius={'2xl'}
          src={blogs && blogs[0]?.mainContent}
        />
        <Stack>
          <Heading
            color={'#000000'}
            fontSize={{ base: '1.5rem', md: '1.4rem', lg: '2rem' }}
            fontWeight={'700'}
            pt={1}
          >
            {blogs && blogs[0]?.title}
          </Heading>
          <Text
            color={'#343434'}
             fontSize={{ base: '1rem', md: '1.1rem', lg: '1rem' }}
            fontWeight={'500'}
            lineHeight={{ base: '25px', md: '25px', lg: '25px' }}
          >
            {blogs && truncateDescription(blogs[0]?.description, 50)}
          </Text>
          <Divider borderColor={'rgba(151, 151, 151, 1)'} my={3} />
          {blogs?.map((blog, index) =>
            // Check if it's not the first element (index > 0)
            index > 0 ? (
              <Stack
                w="100%"
                key={index}
                // alignItems={'center'}
                px={{ base: '5%', xl: '10%' }}
              >
                <Stack w='100%' direction={{ base: 'column', lg: 'row' }} gap={5}>
                  <Img
                    onClick={() => nav('/news-detail', { state: blog?._id })}
                    w={'24%'}
                    cursor="pointer"
                    h={'165px'}
                    src={blog?.mainContent}
                    borderRadius={'xl'}
                  />
                  <Stack w='75%' justifyContent={'center'}>
                    <Heading
                      color={'rgba(0, 0, 0, 0.95)'}
                      fontSize={{ base: '1.1rem', md: '1.2rem', lg: '1.3rem' }}
                      fontWeight={'700'}
                      // cursor={'pointer'}
                      // onClick={() => nav('/news-detail')}
                    >
                      {blog?.title}
                    </Heading>
                    <Text
                      color={'#343434'}
                      fontSize={{ base: '0.95rem', md: '1rem', lg: '1rem' }}
                      fontWeight={'500'}
                    >
                      {truncateDescription(blog?.description, 50)}
                    </Text>
                  </Stack>
                </Stack>
                <Divider
                  borderColor={'rgba(151, 151, 151, 1)'}
                  w={'100%'}
                  my={2}
                />
              </Stack>
            ) : null
          )}
          <Stack>
            <Button
              variant="outline"
              borderRadius={'5px'}
              borderColor={'#418DB8'}
              bgColor={'white'}
              color={'#17496D'}
              fontWeight={'500'}
              fontSize={{ base: '1rem', xl: '1.05rem' }}
              _hover={{
                color: 'white',
                bgColor: '#418DB8',
              }}
              mx={'32%'}
              mt={8}
              mb={5}
            >
              See More
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default HrNews;
