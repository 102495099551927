import React from 'react';
import {
  Button,
  HStack,
  Heading,
  Img,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import arrow from '../../assets/images/Vector.svg';
import augmentation from '../../assets/images/augmentation.jpg';
import hradvisory from '../../assets/images/hradvisory.jpg';
import hrspecificchange from '../../assets/images/hrspecificchange.jpg';
import hrtransformation from '../../assets/images/hrtransformation.jpg';
import { useNavigate } from 'react-router-dom';
const HRServices = () => {
  const nav = useNavigate();
  return (
    <Stack width={'100%'} px={{ base: 3, md: 6, lg: 12, xl: 20 }} py={6}>
      <Heading
        color={'#17496D'}
        fontSize={{ base: '1rem', md: '1.8rem', lg: '2rem' }}
        fontWeight={'700'}
        className="heading"
        mb={4}
      >
        HR Advisory
      </Heading>
      <Spacer />
      <Stack direction={{ base: 'column', md: 'row' }} mb={'8vh'} gap={{base:12,md:10,lg:12,xl:'7vw'}}>
        <Stack w={{ base: '100%', md: '50%' }} spacing={6}>
          <Img
            h={{base:'50vh',lg:'58vh'}}
            borderRadius={'5px'}
            // objectFit={'cover'}
            src={augmentation}
          />
          <Heading
            color={'#000000'}
            fontSize={{ base: '1rem', md: '1.3rem', lg: '1.3rem',xl:'1.5rem' }}
            fontWeight={'bold'}
            className="heading"
            lineHeight={'35px'}
            w={{ base: '100%', xl: '70%' }}
          >
            Talent Management
          </Heading>
          <Text
            color={'#343434'}
            fontSize={{ base: '0.75rem', md: '1.1rem', lg: '1rem' }}
            fontWeight={'500'}
          >
            Welcome to snsHRhub, your gateway to HR transformation through
            talent augmentation. At snsHRhub, we bring together Functional and
            Technical Expertise to elevate your human resources capabilities. As
            your dedicated partner, we go beyond traditional staffing,
            strategically infusing your team with the specialized skills
            essential for success. Dive into the world of HR excellence with
            snsHRhub, where precision meets proficiency.
          </Text>
          <Button
            onClick={() => nav('/staff-augmentation')}
            size={{ base: 'sm', md: 'md' }}
            color="white"
            _hover={{}}
            bg="linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)"
            boxShadow={'0px 2.25px 9px 0px rgba(74, 58, 255, 0.18)'}
            borderRadius={'5px'}
            fontWeight={'400'}
          >
            Learn More
          </Button>
        </Stack>
        <Stack w={{ base: '100%', md: '50%' }} spacing={6}>
          <Img
            h={{base:'50vh',lg:'58vh'}}
            borderRadius={'5px'}
            // objectFit={'cover'}
            src={hrspecificchange}
          />
          <Heading
            color={'#000000'}
            fontSize={{ base: '1rem', md: '1.3rem', lg: '1.3rem',xl:'1.5rem' }}
            fontWeight={'bold'}
            className="heading"
            lineHeight={'35px'}
          >
            HR Change Management
          </Heading>
          <Text
            color={'#343434'}
            fontSize={{ base: '0.75rem', md: '1.1rem', lg: '1rem' }}
            fontWeight={'500'}
          >
            Welcome to a transformative era in human resources, where change is
            not just inevitable but embraced as a catalyst for growth and
            innovation. At snsHRhub, we introduce the SnS Change Model, a
            comprehensive framework designed to navigate the intricacies of HR
            transformation with precision and effectiveness. In this dynamic
            landscape, change is not merely a process; it's an opportunity to
            elevate your human resources practices to new heights.
          </Text>
          <Button
            onClick={() => nav('/specific-change-management')}
            size={{ base: 'sm', md: 'md' }}
            color="white"
            _hover={{}}
            bg="linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)"
            boxShadow={'0px 2.25px 9px 0px rgba(74, 58, 255, 0.18)'}
            borderRadius={'5px'}
            fontWeight={'400'}
          >
            Learn More
          </Button>
        </Stack>
      </Stack>
      <Stack direction={{ base: 'column', md: 'row' }} gap={{base:12,md:10,lg:12,xl:'7vw'}}>
        <Stack w={{ base: '100%', md: '50%' }} spacing={6}>
          <Img
            h={{base:'50vh',lg:'58vh'}}
            borderRadius={'5px'}
            // objectFit={'cover'}
            src={hradvisory}
          />
          <Heading
            color={'#000000'}
            fontSize={{ base: '1rem', md: '1.3rem', lg: '1.3rem',xl:'1.5rem' }}
            fontWeight={'bold'}
            className="heading"
            lineHeight={'35px'}
            w={{ base: '100%', lg: '70%' }}
          >
            HR Technology Advisory
          </Heading>
          <Text
            color={'#343434'}
            fontSize={{ base: '0.75rem', md: '1.1rem', lg: '1rem' }}
            fontWeight={'500'}
          >
            Unlock the power of strategic HR advancement with snsHRhub's
            holistic HR Technology Advisory services. At snsHRhub, we recognize
            the pivotal role technology plays in reshaping human resources, and
            our advisory services are meticulously crafted to guide
            organizations through this dynamic landscape.
          </Text>
          <Button
            size={{ base: 'sm', md: 'md' }}
            color="white"
            _hover={{}}
            bg="linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)"
            boxShadow={'0px 2.25px 9px 0px rgba(74, 58, 255, 0.18)'}
            borderRadius={'5px'}
            fontWeight={'400'}
            onClick={() => nav('/technology-advisory')}
          >
            Learn More
          </Button>
        </Stack>
        <Stack w={{ base: '100%', md: '50%' }} spacing={6}>
          <Img
            h={{base:'50vh',lg:'58vh'}}
            borderRadius={'5px'}
            // objectFit={'cover'}
            src={hrtransformation}
          />
          <Heading
            color={'#000000'}
            fontSize={{ base: '1rem', md: '1.3rem', lg: '1.3rem',xl:'1.5rem' }}
            fontWeight={'bold'}
            className="heading"
            lineHeight={'35px'}
            // w={{ base: '100%', xl: '70%' }}
          >
            HR Digital Transformation Services
          </Heading>
          <Text
            color={'#343434'}
            fontSize={{ base: '0.75rem', md: '1.1rem', lg: '1rem' }}
            fontWeight={'500'}
          >
            Step into the future of Human Resources with snsHRhub's cutting-edge
            HR Digital Transformation Services. At snsHRhub, we redefine the way
            organizations manage their workforce, seamlessly integrating
            technology to enhance efficiency, streamline processes, and elevate
            the overall employee experience.
          </Text>
          <Button
            size={{ base: 'sm', md: 'md' }}
            color="white"
            _hover={{}}
            bg="linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)"
            boxShadow={'0px 2.25px 9px 0px rgba(74, 58, 255, 0.18)'}
            borderRadius={'5px'}
            fontWeight={'400'}
            onClick={() => nav('/digital-transformation')}
          >
            Learn More
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default HRServices;
