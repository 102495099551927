import React from 'react';
import {
  Stack,
  HStack,
  Link,
  Button,
  Heading,
  Spacer,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  Img,
  Text,
  Icon,
  Tooltip,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Drawer from './Drawer';
import technology from '../../assets/images/technology.png';
import advisory from '../../assets/images/hradvisory.jpg';
import specificchange from '../../assets/images/hrspecificchange.jpg';
import digital from '../../assets/images/hrtransformation.jpg';
import logo from '../../assets/images/newslogo.svg';
import { BsChevronDown } from 'react-icons/bs';
import TextTrimmer from './TextTrimmer';
const HrResourcesDropdown = () => {
  const nav = useNavigate();

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          px={0}
          fontWeight={'500'}
          _active={{}}
          border="none"
          _hover={{ color: '#FF6217'}}
          rightIcon={<BsChevronDown />}
          variant="outline"
          color="white"
          w="fit-content"
        >
          HR Resources
        </Button>
      </PopoverTrigger>
      <PopoverContent
        px={4}
        py={4}
        outlineColor={'transparent'}
        _focusVisible={{}}
        bg="white"
        width="full"
      >
        <PopoverArrow />
        <PopoverBody>
          <HStack alignItems={'start'} spacing={16} w="100%">
            <Stack w="250px">
              <Text
                cursor="pointer"
                onClick={() => nav('/hr-policy-template')}
                fontSize={'lg'}
                fontWeight={'600'}
                color="#17496D"
              >
                HR Policy Templates
              </Text>
              <Text fontSize="sm" color="#343434" fontWeight={'500'}>
                Ready-to-use policies for effective HR management.
              </Text>
            </Stack>
            <Stack w="250px">
              <Text
                cursor="pointer"
                onClick={() => nav('/hr-process-template')}
                fontSize={'lg'}
                fontWeight={'600'}
                color="#17496D"
              >
                HR Process Template
              </Text>
              <Text fontSize="sm" color="#343434" fontWeight={'500'}>
                Streamlined HR processes for optimized workflow.
              </Text>
            </Stack>
            <Stack w="250px">
              <Text
                cursor="pointer"
                onClick={() => nav('/toolkits')}
                fontSize={'lg'}
                fontWeight={'600'}
                color="#17496D"
              >
                Toolkits
              </Text>
              <Text fontSize="sm" color="#343434" fontWeight={'500'}>
                Comprehensive HR resources to empower your team.
              </Text>
            </Stack>
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
const HrServicesDropdown = () => {
  const nav = useNavigate();

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          fontWeight={'500'}
          _active={{}}
          _hover={{ color: '#FF6217'}}
          border="none"
          rightIcon={<BsChevronDown />}
          variant="outline"
          color="white"
          w="fit-content"
          px={0}
        >
          HR Advisory
        </Button>
      </PopoverTrigger>
      <PopoverContent
        px={4}
        py={4}
        outlineColor={'transparent'}
        _focusVisible={{}}
        bg="white"
        width="full"
      >
        <PopoverArrow />
        <PopoverBody>
          <HStack alignItems={'start'} spacing={20} w="100%">
            <Stack w="270px">
              <Img
                borderRadius={'3px'}
                h="20vh"
                objectFit={'cover'}
                src={digital}
              />
              <Text pt={3} fontSize={'lg'} fontWeight={'600'} color="#17496D">
                HR Digital Transformation Services
              </Text>
              <TextTrimmer
                text="Step into the future of Human Resources with snsHRhub's
                cutting-edge HR Digital Transformation Services. At snsHRhub, we
                redefine the way organizations manage their workforce,
                seamlessly integrating technology to enhance efficiency,
                streamline processes, and elevate the overall employee
                experience."
                maxWords={25}
              />

              <Button
                size="md"
                color="white"
                _hover={{}}
                fontWeight={'400'}
                bg="linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)"
                boxShadow={'0px 2.25px 9px 0px rgba(74, 58, 255, 0.18)'}
                borderRadius={'5px'}
                onClick={() => nav('/digital-transformation')}
              >
                Learn More
              </Button>
            </Stack>
            <Stack w="270px">
              <Img
                borderRadius={'3px'}
                h="20vh"
                objectFit={'cover'}
                src={specificchange}
              />
              <Text
                pt={3}
                pr={12}
                fontSize={'lg'}
                fontWeight={'600'}
                color="#17496D"
              >
                HR Change Management
              </Text>
              <TextTrimmer
                text="Welcome to a transformative era in human resources, where
                  change is not just inevitable but embraced as a catalyst for
                  growth and innovation. At snsHRhub, we introduce the SnS
                  Change Model, a comprehensive framework designed to navigate
                  the intricacies of HR transformation with precision and
                  effectiveness. In this dynamic landscape, change is not merely
                  a process; it's an opportunity to elevate your human resources
                  practices to new heights"
                maxWords={30}
              />

              <Button
                size="md"
                color="white"
                _hover={{}}
                fontWeight={'400'}
                bg="linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)"
                boxShadow={'0px 2.25px 9px 0px rgba(74, 58, 255, 0.18)'}
                borderRadius={'5px'}
                onClick={() => nav('/specific-change-management')}
              >
                Learn More
              </Button>
            </Stack>
            <Stack spacing={4} w="270px">
              <Stack>
                <Img
                  borderRadius={'3px'}
                  h="20vh"
                  objectFit={'cover'}
                  src={advisory}
                />
                <Text
                  pt={3}
                  pr={16}
                  fontSize={'lg'}
                  fontWeight={'600'}
                  color="#17496D"
                >
                  HR Technology Advisory
                </Text>
                <TextTrimmer
                  text="Unlock the power of strategic HR advancement with snsHRhub's
                holistic HR Technology Advisory services. At snsHRhub, we
                recognize the pivotal role technology plays in reshaping human
                resources, and our advisory services are meticulously crafted
                to guide organizations through this dynamic landscape."
                  maxWords={30}
                />

                <Button
                  size="md"
                  color="white"
                  _hover={{}}
                  bg="linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)"
                  boxShadow={'0px 2.25px 9px 0px rgba(74, 58, 255, 0.18)'}
                  borderRadius={'5px'}
                  onClick={() => nav('/technology-advisory')}
                >
                  Learn More
                </Button>
              </Stack>
            </Stack>
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
const Header = () => {
  const nav = useNavigate();
  return (
    <Stack
      // position={'fixed'}
      width={'100%'}
      bgGradient="linear(15.46deg, #17496D 26.3%, #418DB8 86.4%)"
      // alignItems={{ md: 'none', lg: 'center' }}
      px={{ base: 3, md: 6, lg: 12, xl: 20 }}
      py={2}
    >
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Img w={'100px'} cursor="pointer" onClick={() => nav('/')} src={logo} />

        <Drawer />
        <Stack
          display={{ base: 'none', md: 'none', lg: 'inherit' }}
          // px={10}
          py={2}
        >
          <HStack gap={{ base: '20px', xl: '40px' }}>
            <HrServicesDropdown />
            <Link
              onClick={() => nav('/staff-augmentation')}
              fontWeight={'500'}
              fontSize={{ base: '13px', xl: '16px' }}
              color={'white'}
              _hover={{
                textDecoration: 'none',
                color: '#FF6217',
                // fontWeight: 'bold',
              }}
            >
              Talent Management
            </Link>
            <HrResourcesDropdown />

            <Link
              onClick={() => nav('/about-us')}
              fontWeight={'500'}
              fontSize={{ base: '13px', xl: '16px' }}
              color={'white'}
              _hover={{
                textDecoration: 'none',
                color: '#FF6217',
                // fontWeight: 'bold',
              }}
            >
              About Us
            </Link>
            <Link
              onClick={() => nav('/contact-us')}
              fontWeight={'500'}
              fontSize={{ base: '13px', xl: '16px' }}
              color={'white'}
              _hover={{
                textDecoration: 'none',
                color: '#FF6217',
                // fontWeight: 'bold',
              }}
            >
              Contact Us
            </Link>
            <Link
              onClick={() => nav('/subscribe')}
              fontWeight={'500'}
              fontSize={{ base: '13px', xl: '16px' }}
              color={'white'}
              _hover={{
                textDecoration: 'none',
                color: '#FF6217',
                // fontWeight: 'bold',
              }}
            >
              Subscribe
            </Link>
            <Tooltip
              p={3}
              hasArrow
              label="Our Attract to Alumni Legal (A2AL)AI application swiftly answers your questions, providing efficient legal insights."
            >
              <Button
                borderRadius={'5px'}
                color={'#FF6217'}
                bgColor={'white'}
                fontWeight={'600'}
                boxShadow={'0px 4px 32px 0px rgba(0, 0, 0, 0.25)'}
                fontSize={{ base: '13px', xl: '16px' }}
                px={10}
                _hover={{}}
                onClick={() => window.open('https://hr.snsa2a.ai/', '_blank')}
              >
                A2AL
              </Button>
            </Tooltip>
          </HStack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Header;
